import React from 'react';
import { Trash2 } from 'lucide-react';

const DangerZone = ({ handleDeleteAccount }) => {
  return (
    <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700 max-w-2xl">
      <h3 className="text-lg font-medium text-red-600 dark:text-red-400 mb-4">
        Danger Zone
      </h3>
      <button
        type="button"
        onClick={handleDeleteAccount}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        <Trash2 className="w-4 h-4 mr-2" />
        Delete Account
      </button>
    </div>
  );
};

export default DangerZone; 