import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const TawkToChat = () => {
  return (
    <TawkMessengerReact
      propertyId="674835da4304e3196ae9c545"
      widgetId="1idp14o54"
    />
  );
};

export default TawkToChat;