import React, { useState } from 'react';
import CaseTimeline from './CaseTimeline';
import CaseDocument from './CaseDocument';
import ClientInfo from './ClientInfo';
import { FaEdit, FaTrash, FaClock, FaFileAlt, FaHistory, FaUser } from 'react-icons/fa';

const CaseDetails = ({ case: caseData, onUpdate }) => {
  const [activeTab, setActiveTab] = useState('details');

  const tabs = [
    { id: 'details', label: 'Details', icon: FaFileAlt },
    { id: 'client', label: 'Client', icon: FaUser },
    { id: 'timeline', label: 'Timeline', icon: FaHistory },
    { id: 'documents', label: 'Documents', icon: FaFileAlt },
    { id: 'tasks', label: 'Tasks', icon: FaClock }
  ];

  const mockClientData = {
    name: caseData.client,
    email: 'client@example.com',
    phone: '+1 (555) 123-4567',
    address: '123 Legal Street, Law City, LC 12345',
    portalAccess: true,
    lastLogin: '2024-03-20',
    documentsAccessed: 5,
    communications: [
      {
        type: 'Phone Call',
        description: 'Discussed case strategy',
        timestamp: '2024-03-19T10:30:00Z'
      },
      {
        type: 'Email',
        description: 'Sent document review request',
        timestamp: '2024-03-18T15:45:00Z'
      }
    ]
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 md:p-6 border-b">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
          <div>
            <h2 className="text-xl md:text-2xl font-bold">{caseData.title}</h2>
            <p className="text-gray-600">Client: {caseData.client}</p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {/* Implement edit */}}
              className="p-2 text-gray-600 hover:text-blue-600"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => {/* Implement delete */}}
              className="p-2 text-gray-600 hover:text-red-600"
            >
              <FaTrash />
            </button>
          </div>
        </div>

        <div className="flex flex-wrap -mb-px overflow-x-auto">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center gap-2 px-4 py-2 border-b-2 whitespace-nowrap transition-colors ${
                activeTab === tab.id
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              <tab.icon className="hidden sm:block" />
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      <div className="p-4 md:p-6">
        {activeTab === 'details' && (
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">Case Information</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Status</p>
                  <p className="font-medium">{caseData.status}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Priority</p>
                  <p className="font-medium">{caseData.priority}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Due Date</p>
                  <p className="font-medium">
                    {new Date(caseData.dueDate).toLocaleDateString()}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Case Type</p>
                  <p className="font-medium">{caseData.type}</p>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Description</h3>
              <p className="text-gray-700">{caseData.description}</p>
            </div>
          </div>
        )}

        {activeTab === 'client' && (
          <ClientInfo
            client={mockClientData}
            onUpdate={(updatedClient) => {
              console.log('Client updated:', updatedClient);
            }}
          />
        )}

        {activeTab === 'timeline' && (
          <CaseTimeline caseId={caseData.id} events={caseData.timeline} />
        )}

        {activeTab === 'documents' && (
          <CaseDocument caseId={caseData.id} documents={caseData.documents} />
        )}

        {activeTab === 'tasks' && (
          <div className="text-center text-gray-500 py-8">
            <p className="text-lg">Task management coming soon...</p>
            <p className="text-sm mt-2">Track and manage case-related tasks</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseDetails; 