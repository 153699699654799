import React from 'react';
import { Users } from 'lucide-react';

const WorkspaceStats = ({ workspace }) => {
  if (!workspace) return null;

  const totalMembers = workspace?.members?.length || 0;
  const activeMembers = workspace?.members?.filter(m => m.status === 'active').length || 0;
  const organizationCredits = workspace?.owner?.credits?.balance || 0;

  return (
    <div className="mt-4 grid grid-cols-3 gap-4">
      <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-sm">
        <div className="flex items-center space-x-3">
          <div className="p-2 rounded-full bg-green-100 dark:bg-green-900">
            <svg 
              className="h-6 w-6 text-green-600 dark:text-green-400" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div>
            <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">Organization Credits</h5>
            <p className="mt-1 text-lg font-semibold text-gray-900 dark:text-white">
              {organizationCredits.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-sm">
        <div className="flex items-center space-x-3">
          <div className="p-2 rounded-full bg-blue-100 dark:bg-blue-900">
            <Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">Active Members</h5>
            <p className="mt-1 text-lg font-semibold text-gray-900 dark:text-white">
              {activeMembers}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg shadow-sm">
        <div className="flex items-center space-x-3">
          <div className="p-2 rounded-full bg-purple-100 dark:bg-purple-900">
            <Users className="h-6 w-6 text-purple-600 dark:text-purple-400" />
          </div>
          <div>
            <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Members</h5>
            <p className="mt-1 text-lg font-semibold text-gray-900 dark:text-white">
              {totalMembers}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceStats; 