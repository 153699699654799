import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateCredits, fetchCredits } from '../store/authSlice.js';
import { fetchConversations } from '../store/conversationsSlice.js';
import { Gavel, Send, FileText, Trash2, LogOut, PlusCircle, Menu, UserCircle, Sun, Moon, Crown, Paperclip, X, Briefcase } from 'lucide-react';
import { useTheme } from '../providers/ThemeProvider';
import AnimatedCounter from './AnimatedCounter';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import FeedbackModal from './FeedbackModal';
import api from '../services/api.js';
import MarkdownMessage from './MarkdownMessage';
import { Avatar, FilePreviewBadge, AttachmentPreview } from './chatmodules';
import { AnimatedEllipsis } from './chatmodules';
import MessageActions from './chatmodules/MessageActions';

const Chat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token, credits, creditsStatus } = useSelector(state => state.auth);
  const { conversations, status: conversationsStatus } = useSelector(state => state.conversations);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [streamingResponse, setStreamingResponse] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [messageFeedback, setMessageFeedback] = useState({});
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const chatAreaRef = useRef(null);

  const { isDarkMode, toggleTheme } = useTheme();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState(null);

  useEffect(() => {
    if (!user || !token) {
      navigate('/login');
    } else if (creditsStatus === 'idle') {
      dispatch(fetchCredits());
    }
  }, [user, token, navigate, dispatch, creditsStatus]);

  const loadConversation = useCallback(async (conversationId) => {
    console.log('loadConversation called with:', conversationId);
    try {
      const response = await api.get(`/api/conversations/${conversationId}`);
      console.log('Loaded conversation:', response.data);
      const uniqueMessages = removeDuplicateMessages(response.data.messages);
      console.log('Unique messages:', uniqueMessages);
      setMessages(uniqueMessages);
      setCurrentConversationId(conversationId);
      setStreamingResponse('');
    } catch (error) {
      console.error('Error loading conversation:', error);
      if (error.response?.status === 404) {
        dispatch(fetchConversations());
        setMessages([]);
        setCurrentConversationId(null);
      } else {
        setMessages(prevMessages => [...prevMessages, { role: 'system', content: 'Error loading conversation. Please try again.' }]);
      }
    }
  }, [dispatch]); // Remove token from dependencies

  useEffect(() => {
    if (user && conversationsStatus === 'idle') {
      dispatch(fetchConversations());
    }
    if (currentConversationId) {
      loadConversation(currentConversationId);
    }
  }, [dispatch, user, conversationsStatus, currentConversationId, loadConversation]);

  const handleSendMessage = useCallback(async (e, customMessage = null) => {
    if (e) e.preventDefault();
    const messageToSend = customMessage || inputMessage.trim();
    if (!messageToSend && !selectedFile) return;

    const newUserMessage = {
      role: 'user',
      content: messageToSend,
      ...(selectedFile && { attachment: selectedFile })
    };
    
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInputMessage('');
    setIsLoading(true);
    setIsThinking(true);

    try {
      const response = await fetch(`${api.defaults.baseURL}/api/chat`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: messageToSend,
          conversationId: currentConversationId || '',
          processedContent: selectedFile?.processedContent || null
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message || 'Unknown error'}`);
      }
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let accumulatedResponse = '';
      setIsThinking(false);
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: '', isStreaming: true }
      ]);

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          // After the stream is complete, fetch conversations and select the newest one
          const response = await dispatch(fetchConversations()).unwrap();
          if (!currentConversationId && response.length > 0) {
            // Select the first conversation (newest) since they're sorted by date
            setCurrentConversationId(response[0]._id);
          }
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.trim() && line.startsWith('data: ')) {
            try {
              // Skip parsing if it's the [DONE] message
              if (line.includes('[DONE]')) {
                continue;
              }

              const data = JSON.parse(line.slice(5));

              if (data.type === 'credits_update') {
                // Update Redux store with new credits
                dispatch(updateCredits(data.credits));
                
            
              } else if (data.chunk) {
                // Handle normal message chunk
                accumulatedResponse += data.chunk;
                setMessages(prevMessages => {
                  const updated = [...prevMessages];
                  const lastMessage = updated[updated.length - 1];
                  if (lastMessage.isStreaming) {
                    lastMessage.content = accumulatedResponse;
                    // Preserve the attachment if it exists
                    if (newUserMessage.attachment) {
                      lastMessage.attachment = newUserMessage.attachment;
                    }
                  }
                  return updated;
                });
              }
            } catch (error) {
              // Only log parsing errors for non-[DONE] messages
              if (!line.includes('[DONE]')) {
                console.error('Error parsing chunk:', error);
              }
            }
          }
        }
      }

    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Error sending message. Please try again.');
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setSelectedFile(null); // Clear the file after sending
    }
  }, [inputMessage, selectedFile, currentConversationId, token, dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const removeDuplicateMessages = (messages) => {
    return messages.filter((message, index, self) =>
      index === self.findIndex((t) => t.role === message.role && t.content === message.content)
    );
  };

  const deleteConversation = async (conversationId, e) => {
    e.stopPropagation();
    setConversationToDelete(conversationId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!conversationToDelete) return;

    // Find the conversation element and add red background
    const conversationElement = document.querySelector(`div[data-conversation-id="${conversationToDelete}"]`);
    if (conversationElement) {
      conversationElement.style.backgroundColor = 'rgb(239, 68, 68)';
      conversationElement.style.transition = 'background-color 0.3s ease';
    }

    try {
      await api.delete(`/api/conversations/${conversationToDelete}`);
      dispatch(fetchConversations());
      if (currentConversationId === conversationToDelete) {
        setMessages([]);
        setCurrentConversationId(null);
      }
      toast.success('Conversation deleted successfully');
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast.error('Failed to delete conversation');
      if (conversationElement) {
        conversationElement.style.backgroundColor = '';
      }
    } finally {
      setIsDeleteModalOpen(false);
      setConversationToDelete(null);
    }
  };

  useEffect(() => {
    console.log('Chat state updated:', {
      messages: messages.map(m => ({ role: m.role, content: m.content.substring(0, 50) + '...' })),
      currentConversationId,
      streamingResponse: streamingResponse.substring(0, 50) + '...',
      isThinking,
      isSidebarOpen,
      isLoading
    });

    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [messages, currentConversationId, streamingResponse, isThinking, isSidebarOpen, isLoading]);

  useEffect(() => {
    console.log('Messages state updated:', messages);
  }, [messages]);

  const templateMessages = [
    "What are the requirements for incorporating a company in Nigeria?",
    "Explain the process of filing for divorce in Nigeria.",
    "What are the labor laws regarding minimum wage in Nigeria?",
    "How does Nigerian copyright law protect software developers?",
  ];

  const groupConversations = useCallback((conversations) => {
    const now = new Date();
    const groups = [
      { title: 'Today', conversations: [] },
      { title: 'Yesterday', conversations: [] },
      { title: 'Last 7 Days', conversations: [] },
      { title: 'Last 30 Days', conversations: [] },
      { title: 'Older', conversations: [] }
    ];

    conversations.forEach(conv => {
      const convDate = new Date(conv.updatedAt || conv.createdAt);
      const diffDays = Math.floor((now - convDate) / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        groups[0].conversations.push(conv);
      } else if (diffDays === 1) {
        groups[1].conversations.push(conv);
      } else if (diffDays < 7) {
        groups[2].conversations.push(conv);
      } else if (diffDays < 30) {
        groups[3].conversations.push(conv);
      } else {
        groups[4].conversations.push(conv);
      }
    });

    return groups.filter(group => group.conversations.length > 0);
  }, []);

  const userConversations = conversations.filter(conv => conv.userId === user?._id);

  // Update the handleKeyPress function
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      // Only handle send if not uploading
      if (!isUploading) {
        handleSendMessage(e);
      } else {
        // Optionally show a toast notification
        toast.warning('Please wait for file upload to complete');
      }
    }
  };

  const handleFeedback = async (index, isHelpful) => {
    // Store the feedback type first without sending to API
    setMessageFeedback(prev => ({
      ...prev,
      [index]: isHelpful
    }));
    
    // Set the selected message index
    setSelectedMessageIndex(index);
    
    // If not helpful, show modal immediately
    // If helpful, show modal for positive feedback
    setIsFeedbackModalOpen(true);
  };

  const handleDetailedFeedback = async (feedback, category = 'other') => {
    try {
      if (!currentConversationId || selectedMessageIndex === null) {
        console.error('Missing conversation ID or message index:', {
          conversationId: currentConversationId,
          messageIndex: selectedMessageIndex
        });
        return;
      }

      const feedbackData = {
        isHelpful: messageFeedback[selectedMessageIndex],
        details: feedback,
        category,
        submittedAt: new Date().toISOString()
      };

      const response = await api.post(
        `/api/conversations/${currentConversationId}/messages/${selectedMessageIndex}/feedback`,
        feedbackData,
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          const targetMessage = updatedMessages[selectedMessageIndex];
          if (targetMessage && targetMessage.role === 'assistant') {
            targetMessage.feedback = feedbackData;
          }
          return updatedMessages;
        });

        toast.success('Thank you for your feedback!', {
          duration: 2000,
          position: 'bottom-center',
          style: {
            background: isDarkMode ? '#374151' : '#ffffff',
            color: isDarkMode ? '#ffffff' : '#000000',
          },
        });
        setIsFeedbackModalOpen(false);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback. Please try again.');
    }
  };

  // Update handleFileUpload function
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Check file size
    const maxSize = 50 * 1024 * 1024; // 50MB
    if (file.size > maxSize) {
      toast.error('File size exceeds 50MB limit');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000, // 30 second timeout
      });

      if (response.data.success) {
        const fileData = response.data.file;
        setSelectedFile({
          name: fileData.name,
          type: fileData.type,
          preview: fileData.preview,
          processedContent: fileData.processedContent
        });
        
        // Show success message with file name
        toast.success(`Successfully processed: ${fileData.name}`);
        
        // Automatically add a system message about the processed document
        const systemMessage = `I've analyzed the document "${fileData.name}". How can I help you with it?`;
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: systemMessage
        }]);
      } else {
        throw new Error(response.data.error || 'Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.response?.data?.error || 'Error uploading file');
      setSelectedFile(null);
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Update the Paperclip button click handler
  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  // Add click handler for overlay
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      <div><Toaster/></div>
      
      {/* Overlay for mobile - only shows when sidebar is open */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={handleOverlayClick}
        />
      )}
      
      {/* Sidebar */}
      <aside className={`fixed inset-y-0 left-0 z-50 w-64 md:w-72 lg:w-80 bg-gray-900 dark:bg-gray-800 text-white transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 flex flex-col`}>
        {/* Header */}
        <div className="flex-shrink-0 flex items-center justify-between p-4 border-b border-gray-800">
          <div className="flex items-center space-x-2">
            <Gavel className="w-8 h-8" />
            <span className="text-xl font-bold">ModulawAI</span>
          </div>
          {/* Close button for mobile */}
          <button
            className="lg:hidden p-2 hover:bg-gray-800 rounded-md"
            onClick={() => setIsSidebarOpen(false)}
            aria-label="Close sidebar"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* New Chat Button */}
        <div className="flex-shrink-0 p-4">
          <button
            className="w-full bg-blue-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-blue-700"
            onClick={() => {
              setMessages([]);
              setCurrentConversationId(null);
            }}
          >
            <PlusCircle className="mr-2 h-4 w-4" /> New Chat
          </button>
        </div>

        {/* Conversations List - Make it scrollable */}
        <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 dark:scrollbar-thumb-gray-400 scrollbar-track-transparent hover:scrollbar-thumb-gray-500 dark:hover:scrollbar-thumb-gray-300">
          <div className="px-4">
            {groupConversations(userConversations).map((group) => (
              <div key={group.title} className="mb-0">
                <h3 className="text-sm font-semibold mb-0">{group.title}</h3>
                {group.conversations.map((conv) => (
                  <div
                    key={conv._id}
                    data-conversation-id={conv._id}
                    className={`w-full flex items-center px-4 py-2 rounded-md group relative transition-colors ${
                      currentConversationId === conv._id 
                        ? 'bg-gray-700 dark:bg-gray-600' 
                        : 'hover:bg-gray-800 dark:hover:bg-gray-700'
                    }`}
                  >
                    <div 
                      className="flex items-center min-w-0 flex-1 cursor-pointer"
                      onClick={() => loadConversation(conv._id)}
                    >
                      <FileText className={`h-4 w-4 mr-2 flex-shrink-0 ${
                        currentConversationId === conv._id 
                          ? 'text-blue-400'
                          : ''
                      }`} />
                      <span className={`truncate text-sm max-w-[140px] sm:max-w-[160px] md:max-w-[200px] ${
                        currentConversationId === conv._id 
                          ? 'font-medium'
                          : ''
                      }`}>
                        {conv.title || 'Untitled Conversation'}
                      </span>
                    </div>
                    <button
                      onClick={(e) => deleteConversation(conv._id, e)}
                      className="p-1 hover:bg-red-600 rounded-md ml-2 opacity-0 group-hover:opacity-100 transition-all duration-200"
                      aria-label="Delete conversation"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/* Case Manager Button - Moved above profile section */}
        <div className="flex-shrink-0 p-4 border-t border-gray-800">
          <Link
            to="/case-manager"
            className="w-full bg-gray-800 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-gray-700 transition-colors relative"
          >
            <Briefcase className="mr-2 h-4 w-4" /> Case Manager
            <span className="absolute -top-2 -right-2 bg-blue-500 text-xs px-1.5 py-0.5 rounded-full text-white font-medium animate-pulse">
              BETA
            </span>
          </Link>
        </div>

        {/* Profile Section - Fixed at bottom */}
        <div className="flex-shrink-0 border-t border-gray-800 p-4 space-y-4">
          {/* Plan Status Card */}
          <div className="bg-gray-800 rounded-lg p-3">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center space-x-2">
                <Crown className="h-4 w-4 text-yellow-500" />
                <span className="text-sm font-medium text-gray-200">Current Plan</span>
              </div>
              <span className={`text-xs px-2 py-0.5 rounded-full ${
                user?.plan?.type === 'Professional' 
                  ? 'bg-blue-500 text-white'
                  : user?.plan?.type === 'Enterprise'
                  ? 'bg-purple-500 text-white'
                  : user?.plan?.type === 'Essential'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-500 text-white'
              }`}>
                {user?.plan?.type || 'Trial'}
              </span>
            </div>
            {/* Update the upgrade button to navigate to profile with subscription tab */}
            <button 
              onClick={() => navigate('/profile?tab=subscription')}
              className="w-full text-center text-xs bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white py-1.5 px-3 rounded-md transition-all duration-200 transform hover:scale-[1.02]"
            >
              Upgrade Plan
            </button>
          </div>

          {/* User Profile Link */}
          <Link 
            to="/profile" 
            className="flex items-center space-x-2 hover:bg-gray-800 p-2 rounded-md transition-colors"
          >
            <div className="w-10 h-10 rounded-full bg-gray-700 flex items-center justify-center">
              {user?.username?.[0]?.toUpperCase() || 'U'}
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-gray-200">{user?.username}</p>
              <p className="text-xs text-gray-400">{user?.email}</p>
            </div>
            <UserCircle className="h-5 w-5 text-gray-400" />
          </Link>

          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="w-full bg-red-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-red-700"
          >
            <LogOut className="mr-2 h-4 w-4" /> Logout
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <header className="bg-gray-200 dark:bg-gray-800 shadow-sm p-3 sm:p-4 flex items-center">
          {/* Left side - Menu button (mobile only) */}
          <div className="lg:hidden flex-shrink-0 w-[80px] sm:w-[100px]">
            <button
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
              onClick={() => setIsSidebarOpen(true)}
              aria-label="Open sidebar"
            >
              <Menu className="h-5 w-5 sm:h-6 sm:w-6 dark:text-gray-200" />
            </button>
          </div>

          {/* Center - Credits Display */}
          <div className="flex-1 flex justify-center items-center">
            <div className="absolute left-1/2 transform -translate-x-1/2 
              bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white 
              px-2 sm:px-4 py-1.5 sm:py-2 rounded-lg shadow-sm 
              flex items-center space-x-2 sm:space-x-3 
              border border-gray-200 dark:border-gray-600
              max-w-[180px] sm:max-w-none"
            >
              <span className="hidden xs:inline text-xs sm:text-sm font-medium uppercase tracking-wider whitespace-nowrap">
                Credits
              </span>
              <div className={`${credits < 100 ? 'text-red-500' : 'text-green-500'} 
                font-bold text-base sm:text-lg flex items-center whitespace-nowrap`}
              >
                <AnimatedCounter value={credits} />
                <button
                  onClick={() => navigate('/profile?tab=subscription')}
                  className="ml-1 sm:ml-2 hover:bg-gray-200 dark:hover:bg-gray-600 
                    p-1 rounded-full transition-colors"
                  title="Add more credits"
                >
                  <PlusCircle className="h-3 w-3 sm:h-4 sm:w-4 text-blue-500" />
                </button>
              </div>
            </div>
          </div>

          {/* Right side - Theme Toggle */}
          <div className="flex-shrink-0 w-[80px] sm:w-[100px] flex justify-end">
            <button
              onClick={toggleTheme}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 
                transition-colors flex items-center space-x-1 sm:space-x-2"
              aria-label="Toggle theme"
            >
              {isDarkMode ? (
                <>
                  <Moon className="h-5 w-5 text-gray-600" />
                  <span className="hidden sm:inline text-sm text-gray-600 dark:text-gray-300">
                    Light
                  </span>
                </>
              ) : (
                <>
                  <Sun className="h-5 w-5 text-yellow-500" />
                  <span className="hidden sm:inline text-sm text-gray-600 dark:text-gray-300">
                    Dark
                  </span>
                </>
              )}
            </button>
          </div>
        </header>
        
        {/* Chat Area */}
        <div 
          className="flex-1 overflow-y-auto p-4 bg-gray-100 dark:bg-gray-900 scrollbar-thin scrollbar-thumb-gray-600 dark:scrollbar-thumb-gray-400 scrollbar-track-transparent hover:scrollbar-thumb-gray-500 dark:hover:scrollbar-thumb-gray-300" 
          ref={chatAreaRef}
        >
          {messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full">
              <h2 className="text-2xl font-bold mb-4 dark:text-white">Start a new conversation</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full max-w-3xl mx-auto px-4">
                {templateMessages.map((template, index) => (
                  <button
                    key={index}
                    className="p-4 bg-white dark:bg-gray-800 shadow-sm rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 text-left dark:text-white"
                    onClick={() => setInputMessage(template)}
                  >
                    {template}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              {messages.map((msg, index) => (
                <div
                  key={`${msg.role}-${index}`}
                  className={`flex items-start space-x-2 ${msg.role === 'user' ? 'flex-row-reverse space-x-reverse' : 'flex-row'}`}
                >
                  <Avatar role={msg.role} username={user?.username} />
                  <div
                    className={`max-w-[85%] sm:max-w-[80%] md:max-w-[75%] rounded-lg p-3 sm:p-4 ${
                      msg.role === 'user'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200 dark:bg-gray-700'
                    }`}
                  >
                    <p className={`text-sm font-semibold mb-1 ${
                      msg.role === 'assistant' ? 'text-gray-900 dark:text-white' : ''
                    }`}>
                      {msg.role === 'user' ? 'You' : 'ModulawAI'}
                    </p>
                    <div className={`${
                      msg.role === 'assistant' 
                        ? 'whitespace-normal text-gray-900 dark:text-white' 
                        : 'whitespace-pre-wrap'
                    }`}>
                      {msg.role === 'assistant' ? (
                        <MarkdownMessage 
                          content={msg.content} 
                          isDarkMode={isDarkMode}
                        />
                      ) : (
                        <>
                          {msg.content}
                          {msg.attachment && <AttachmentPreview attachment={msg.attachment} />}
                        </>
                      )}
                    </div>
                    
                    {/* Add feedback controls for assistant messages */}
                    {msg.role === 'assistant' && (
                      <MessageActions 
                        content={msg.content}
                        isDarkMode={isDarkMode}
                        messageFeedback={messageFeedback}
                        index={index}
                        onFeedback={handleFeedback}
                      />
                    )}
                  </div>
                </div>
              ))}
              {isThinking && (
                <div className="flex items-start space-x-2">
                  <Avatar role="assistant" />
                  <div className="max-w-[80%] bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-lg p-4">
                    <p className="text-sm font-semibold mb-1">ModulawAI</p>
                    <p>
                      <AnimatedEllipsis />
                    </p>
                  </div>
                </div>
              )}
              {isLoading && !isThinking && streamingResponse && (
                <div className="flex justify-start">
                  <div className="max-w-[80%] bg-gray-200 text-gray-900 rounded-lg p-4">
                    <p className="text-sm font-semibold mb-0">ModulawAI</p>
                    <div className="whitespace-pre-wrap">{streamingResponse}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Input Area */}
        <footer className="bg-gray-200 dark:bg-gray-800 border-t dark:border-gray-700 p-3 sm:p-4">
          <form onSubmit={handleSendMessage} className="space-y-3 sm:space-y-4">
            <div className="relative flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 ml-4">
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                className="hidden"
                accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png,.gif"
              />
              
              {/* Attachment button */}
              <div className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/3 z-10">
                <button
                  type="button"
                  className="p-2 sm:p-2.5 text-white bg-blue-600 hover:bg-blue-700 rounded-full transition-colors flex items-center justify-center relative overflow-hidden w-10 h-10 sm:w-11 sm:h-11"
                  onClick={handleAttachmentClick}
                  disabled={isUploading}
                >
                  <div className="absolute inset-0 rounded-full">
                    <div className="animate-wave absolute inset-0 opacity-50 rounded-full" />
                    <div className="animate-wave-delayed absolute inset-0 opacity-30 rounded-full" />
                  </div>
                  {isUploading ? (
                    <div className="animate-spin">
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                    </div>
                  ) : (
                    <Paperclip className="h-6 w-6 z-10" />
                  )}
                </button>
              </div>
              
              {/* Textarea */}
              <textarea
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Don't be shy, type your research questions here..."
                className="flex-1 p-2 pl-6 pr-12 border rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 
                  bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:placeholder-gray-400
                  text-sm sm:text-base min-h-[80px] sm:min-h-[100px]"
                rows="3"
              />
              
              {/* File preview badge */}
              <div className="absolute right-12 sm:right-12 top-2">
                <FilePreviewBadge 
                  file={selectedFile} 
                  onRemove={() => setSelectedFile(null)} 
                />
              </div>
              
              {/* Updated Send button */}
              <button
                type="submit"
                disabled={isLoading || isUploading}
                className={`absolute right-2 bottom-2 p-2 rounded-full 
                  bg-blue-600 text-white hover:bg-blue-700 
                  disabled:opacity-50 disabled:cursor-not-allowed
                  transition-colors flex items-center justify-center
                  ${isUploading ? 'bg-gray-400' : ''}`}
                title={isUploading ? 'Please wait for file upload to complete' : 'Send message'}
              >
                {isUploading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
          <p className="text-xs sm:text-sm text-gray-400 mt-2 text-center italic px-4">
            While this is a great tool for research, please verify any legal advice with a qualified professional.
          </p>
        </footer>
      </div>

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6 shadow-xl">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Delete Conversation
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              Are you sure you want to delete this conversation? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setConversationToDelete(null);
                }}
                className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        onSubmit={handleDetailedFeedback}
        initialFeedback={selectedMessageIndex !== null ? messageFeedback[selectedMessageIndex] : null}
      />
    </div>
  );
};

export default Chat;