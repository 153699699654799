import React, { useEffect, useState } from 'react';

const AnimatedCounter = ({ value, duration = 1000 }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (value !== displayValue) {
      setIsAnimating(true);
      const startValue = displayValue;
      const endValue = value;
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        // Easing function for mechanical feel
        const easeOutBack = (x) => {
          const c1 = 1.70158;
          const c3 = c1 + 1;
          return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
        };

        const currentValue = Math.round(
          startValue + (endValue - startValue) * easeOutBack(progress)
        );

        setDisplayValue(currentValue);

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          setIsAnimating(false);
        }
      };

      requestAnimationFrame(animate);
    }
  }, [value, duration, displayValue]);

  return (
    <div className="font-mono relative overflow-hidden">
      <div
        className={`flex items-center justify-center transition-transform ${
          isAnimating ? 'animate-shake' : ''
        }`}
      >
        {String(displayValue)
          .split('')
          .map((digit, index) => (
            <div
              key={index}
              className="w-4 mx-0.5 bg-gray-800 dark:bg-gray-700 rounded-sm relative overflow-hidden"
            >
              <div
                className={`text-center py-1 ${
                  isAnimating ? 'animate-slide-digit' : ''
                }`}
              >
                {digit}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AnimatedCounter;