import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, Link, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../store/authSlice.js';
import { useForm } from 'react-hook-form';
import api from '../services/api';
import { toast } from 'react-hot-toast';

// First, let's memoize the Modal component to prevent unnecessary re-renders
const Modal = React.memo(({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  // Prevent event bubbling
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-8 max-w-md w-full mx-4 shadow-xl"
        onClick={handleModalClick} // Prevent closing when clicking inside modal
      >
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-semibold text-gray-800">{title}</h3>
          <button 
            onClick={onClose} 
            className="text-gray-600 hover:text-gray-800 transition-colors"
            type="button"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
});

// Add display name for the memoized component
Modal.displayName = 'Modal';

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnUrl');
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetStatus, setResetStatus] = useState('');
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const { register, handleSubmit } = useForm();
  const [searchParams] = useSearchParams();
  const verificationParam = searchParams.get('verified');
  const [invitePending, setInvitePending] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const invitePending = params.get('invitePending');
    
    if (email) {
      // Pre-fill email in the form
      register('usernameOrEmail', { value: email });
    }
    
    if (invitePending === 'true') {
      setInvitePending(true);
    }
  }, [register]);

  const handleLoginSuccess = () => {
    if (invitePending) {
      navigate('/chat');
      toast.success('Successfully logged in! You can now accept the workspace invitation.');
    } else if (returnUrl) {
      navigate(decodeURIComponent(returnUrl));
    } else {
      navigate('/dashboard');
    }
  };

  const onSubmit = async (values) => {
    try {
      const loginData = {
        usernameOrEmail: values.usernameOrEmail,
        password: values.password
      };
      
      console.log('Submitting login data:', loginData);
      
      const result = await dispatch(login(loginData)).unwrap();
      
      if (result.token) {
        handleLoginSuccess();
      }
    } catch (error) {
      console.error('Login error details:', error);
      setError(error || 'Failed to login');
    }
  };

  // Optimize the handlers using useCallback
  const handlePasswordReset = useCallback(async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      setResetStatus('Please enter your email address');
      return;
    }

    try {
      const response = await api.post('/api/auth/reset-password', { email: resetEmail });
      setResetStatus(response.data.message);
      setTimeout(() => {
        setIsResetModalOpen(false);
        setResetStatus('');
        setResetEmail('');
      }, 3000);
    } catch (error) {
      setResetStatus(error.response?.data?.message || 'Error sending reset email');
    }
  }, [resetEmail]);

  const handleResendVerification = useCallback(async (e) => {
    e.preventDefault();
    if (!verificationEmail) {
      setVerificationStatus('Please enter your email address');
      return;
    }

    try {
      const response = await api.post('/api/auth/resend-verification', { email: verificationEmail });
      setVerificationStatus(response.data.message);
      setTimeout(() => {
        setIsVerificationModalOpen(false);
        setVerificationStatus('');
        setVerificationEmail('');
      }, 3000);
    } catch (error) {
      setVerificationStatus(error.response?.data?.message || 'Error resending verification email');
    }
  }, [verificationEmail]);

  // Optimize the input change handlers
  const handleResetEmailChange = useCallback((e) => {
    setResetEmail(e.target.value);
  }, []);

  const handleVerificationEmailChange = useCallback((e) => {
    setVerificationEmail(e.target.value);
  }, []);

  // Create memoized modal contents
  const resetModalContent = (
    <form onSubmit={handlePasswordReset} className="space-y-5">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-800">
          Email Address
        </label>
        <input
          type="email"
          value={resetEmail}
          onChange={handleResetEmailChange}
          className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-gray-800 font-medium transition-colors"
          placeholder="Enter your email"
          autoComplete="email"
        />
      </div>
      {resetStatus && (
        <p className={`text-sm font-medium ${
          resetStatus.includes('Error') ? 'text-red-600' : 'text-green-600'
        }`}>
          {resetStatus}
        </p>
      )}
      <button
        type="submit"
        className="w-full bg-blue-600 text-white rounded-lg px-4 py-3 font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
      >
        Send Reset Link
      </button>
    </form>
  );

  const verificationModalContent = (
    <form onSubmit={handleResendVerification} className="space-y-5">
      <div className="space-y-2">
        <label className="block text-sm font-semibold text-gray-800">
          Email Address
        </label>
        <input
          type="email"
          value={verificationEmail}
          onChange={handleVerificationEmailChange}
          className="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-gray-800 font-medium transition-colors"
          placeholder="Enter your email"
          autoComplete="email"
        />
      </div>
      {verificationStatus && (
        <p className={`text-sm font-medium ${
          verificationStatus.includes('Error') ? 'text-red-600' : 'text-green-600'
        }`}>
          {verificationStatus}
        </p>
      )}
      <button
        type="submit"
        className="w-full bg-blue-600 text-white rounded-lg px-4 py-3 font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
      >
        Resend Verification Email
      </button>
    </form>
  );

  return (
    <div className="bg-blue-900 min-h-screen bg-gradient-to-b from-blue-900 via-blue-800 to-blue-600 font-[Poppins] pt-16 sm:pt-0">
      <div className="relative min-h-screen sm:flex sm:flex-row justify-center bg-transparent rounded-3xl shadow-xl">
        <div className="flex-col flex self-center lg:px-14 sm:max-w-4xl xl:max-w-md z-10">
          <div className="self-start hidden lg:flex flex-col text-gray-300">
            <h1 className="my-3 font-bold text-5xl font-[Inter] tracking-tight">
              Welcome back
            </h1>
            <p className="pr-3 text-lg font-light opacity-85">
              Access your ModulawAI account to continue your conversation and case management
            </p>
          </div>
        </div>
        
        <div className="flex justify-center self-center z-10 px-4 sm:px-0">
          <div className="p-8 sm:p-12 bg-white mx-auto rounded-3xl w-full sm:w-96 shadow-xl">
            <div className="mb-7">
              <h3 className="font-bold text-3xl text-gray-800 font-[Inter]">Sign In</h3>
              <p className="text-gray-500 font-light mt-2">
                Don't have an account?{' '}
                <Link to="/signup" className="text-sm text-blue-700 hover:text-blue-800 font-semibold">
                  Sign Up
                </Link>
              </p>
            </div>

            {error && (
              <div className="mb-6 p-4 text-red-700 bg-red-100 rounded-lg font-medium">
                {error}
              </div>
            )}

            {verificationParam === 'pending' && (
              <div className="mb-6 p-4 text-blue-700 bg-blue-100 rounded-lg font-medium">
                Please check your email to verify your account before logging in.
              </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <input
                {...register("usernameOrEmail", { required: true })}
                className="w-full text-sm px-4 py-3 bg-gray-100 focus:bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400 text-gray-900 font-normal transition-colors duration-300"
                type="text"
                placeholder="Username or Email"
              />

              <div className="relative">
                <input
                  {...register("password", { required: true })}
                  className="w-full text-sm px-4 py-3 bg-gray-100 focus:bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:border-blue-400 text-gray-900 font-normal transition-colors duration-300"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                />
                <div className="flex items-center absolute inset-y-0 right-0 mr-3 text-sm leading-5">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="text-blue-700"
                  >
                    {showPassword ? (
                      <svg className="h-4" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path fill="currentColor" d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                      </svg>
                    ) : (
                      <svg className="h-4" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <div className="flex justify-between text-sm">
                <button
                  type="button"
                  onClick={() => setIsResetModalOpen(true)}
                  className="text-blue-700 hover:text-blue-800 font-medium"
                >
                  Forgot Password?
                </button>
                <button
                  type="button"
                  onClick={() => setIsVerificationModalOpen(true)}
                  className="text-blue-700 hover:text-blue-800 font-medium"
                >
                  Resend Verification Email
                </button>
              </div>

              <button
                type="submit"
                className="w-full flex justify-center bg-blue-800 hover:bg-blue-700 text-gray-100 p-3 rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-300 hover:shadow-lg"
              >
                Sign in
              </button>
            </form>

            <div className="flex items-center justify-center space-x-2 my-5">
              <span className="h-px w-16 bg-gray-200"></span>
              <span className="text-gray-400 font-normal text-sm">or continue with</span>
              <span className="h-px w-16 bg-gray-200"></span>
            </div>

            <div className="flex justify-center gap-5 w-full">
              <div className="relative w-full">
                <button 
                  type="button"
                  disabled
                  className="w-full flex items-center justify-center mb-6 md:mb-0 border border-gray-300 text-sm text-gray-400 p-3 rounded-lg tracking-wide font-medium cursor-not-allowed opacity-60"
                >
                  <svg className="w-4 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path 
                      fill="#EA4335" 
                      d="M5.266 9.765A7.077 7.077 0 0 1 12 4.909c1.69 0 3.218.6 4.418 1.582L19.91 3C17.782 1.145 15.055 0 12 0 7.27 0 3.198 2.698 1.24 6.65l4.026 3.115Z"
                    />
                    <path 
                      fill="#34A853" 
                      d="M16.04 18.013c-1.09.703-2.474 1.078-4.04 1.078a7.077 7.077 0 0 1-6.723-4.823l-4.04 3.067A11.965 11.965 0 0 0 12 24c2.933 0 5.735-1.043 7.834-3l-3.793-2.987Z"
                    />
                    <path 
                      fill="#4A90E2" 
                      d="M19.834 21c2.195-2.048 3.62-5.096 3.62-9 0-.71-.109-1.473-.272-2.182H12v4.637h6.436c-.317 1.559-1.17 2.766-2.395 3.558L19.834 21Z"
                    />
                    <path 
                      fill="#FBBC05" 
                      d="M5.277 14.268A7.12 7.12 0 0 1 4.909 12c0-.782.125-1.533.357-2.235L1.24 6.65A11.934 11.934 0 0 0 0 12c0 1.92.445 3.73 1.237 5.335l4.04-3.067Z"
                    />
                  </svg>
                  <span className="ml-2">Google</span>
                </button>
                <span className="absolute -top-2 -right-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                  Coming Soon
                </span>
              </div>

              <div className="relative w-full">
                <button 
                  type="button"
                  disabled
                  className="w-full flex items-center justify-center mb-6 md:mb-0 border border-gray-300 text-sm text-gray-400 p-3 rounded-lg tracking-wide font-medium cursor-not-allowed opacity-60"
                >
                  <svg className="w-4 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill="#1877F2"
                      d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"
                    />
                  </svg>
                  <span className="ml-2">Facebook</span>
                </button>
                <span className="absolute -top-2 -right-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                  Coming Soon
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Password Reset Modal */}
      <Modal
        isOpen={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        title="Reset Password"
      >
        {resetModalContent}
      </Modal>

      {/* Verification Email Modal */}
      <Modal
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        title="Resend Verification Email"
      >
        {verificationModalContent}
      </Modal>

      <svg 
        className="absolute bottom-0 left-0" 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1440 320"
      >
        <path 
          fill="#fff" 
          fillOpacity="1" 
          d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        />
      </svg>
    </div>
  );
};

export default LoginForm;