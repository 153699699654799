import React, { useState } from 'react';
import { FaPlus, FaEdit, FaTrash, FaCheck, FaClock, FaExclamationCircle, FaFileAlt, FaComment, FaGavel } from 'react-icons/fa';
import { caseService } from '../../services/caseService';
import { toast } from 'react-toastify';

const CaseTimeline = ({ caseId, events = [], onUpdate }) => {
  const [showEventForm, setShowEventForm] = useState(false);
  const [newEvent, setNewEvent] = useState({
    type: 'UPDATE',
    description: '',
    importance: 'normal'
  });

  const getEventIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'update': return <FaCheck className="text-green-500" />;
      case 'deadline': return <FaClock className="text-orange-500" />;
      case 'alert': return <FaExclamationCircle className="text-red-500" />;
      case 'document': return <FaFileAlt className="text-blue-500" />;
      case 'communication': return <FaComment className="text-purple-500" />;
      case 'court': return <FaGavel className="text-indigo-500" />;
      default: return <FaCheck className="text-gray-500" />;
    }
  };

  const eventTypes = [
    { value: 'UPDATE', label: 'Status Update' },
    { value: 'DEADLINE', label: 'Deadline' },
    { value: 'ALERT', label: 'Alert' },
    { value: 'DOCUMENT', label: 'Document' },
    { value: 'COMMUNICATION', label: 'Communication' },
    { value: 'COURT', label: 'Court Event' }
  ];

  const handleAddEvent = async (e) => {
    e.preventDefault();
    try {
      const eventData = {
        ...newEvent,
        timestamp: new Date().toISOString()
      };

      const addedEvent = await caseService.addTimelineEvent(caseId, eventData);
      onUpdate?.([addedEvent, ...events]);
      toast.success('Event added successfully');
      setShowEventForm(false);
      setNewEvent({ type: 'UPDATE', description: '', importance: 'normal' });
    } catch (error) {
      console.error('Error adding event:', error);
      toast.error('Failed to add event');
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        // Implement delete functionality
        toast.success('Event deleted successfully');
      } catch (error) {
        console.error('Error deleting event:', error);
        toast.error('Failed to delete event');
      }
    }
  };

  const getEventColor = (importance) => {
    switch (importance) {
      case 'high': return 'border-red-500 bg-red-50';
      case 'medium': return 'border-yellow-500 bg-yellow-50';
      case 'low': return 'border-green-500 bg-green-50';
      default: return 'border-blue-500 bg-blue-50';
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Timeline</h3>
        <button 
          onClick={() => setShowEventForm(true)}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
        >
          <FaPlus /> Add Event
        </button>
      </div>

      {showEventForm && (
        <form onSubmit={handleAddEvent} className="bg-white p-4 rounded-lg shadow-sm space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Event Type</label>
            <select
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newEvent.type}
              onChange={(e) => setNewEvent({ ...newEvent, type: e.target.value })}
            >
              {eventTypes.map(type => (
                <option key={type.value} value={type.value}>{type.label}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              rows="3"
              value={newEvent.description}
              onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Importance</label>
            <select
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={newEvent.importance}
              onChange={(e) => setNewEvent({ ...newEvent, importance: e.target.value })}
            >
              <option value="low">Low</option>
              <option value="normal">Normal</option>
              <option value="high">High</option>
            </select>
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => setShowEventForm(false)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Add Event
            </button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {events.map((event, index) => (
          <div 
            key={event.id || index} 
            className={`relative pl-8 pb-4 ${index !== events.length - 1 ? 'border-l-2 border-gray-200' : ''}`}
          >
            <div className={`absolute left-0 top-2 w-8 h-8 rounded-full flex items-center justify-center ${
              getEventColor(event.importance)
            }`}>
              {getEventIcon(event.type)}
            </div>
            <div className="ml-4">
              <div className="flex justify-between items-start">
                <div>
                  <div className="text-sm text-gray-500">
                    {new Date(event.timestamp).toLocaleString()}
                  </div>
                  <div className="font-medium">{eventTypes.find(t => t.value === event.type)?.label}</div>
                </div>
                <div className="flex gap-2">
                  <button 
                    className="text-gray-400 hover:text-blue-600"
                    onClick={() => {/* Implement edit */}}
                  >
                    <FaEdit />
                  </button>
                  <button 
                    className="text-gray-400 hover:text-red-600"
                    onClick={() => handleDeleteEvent(event.id)}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
              <div className="text-gray-600 mt-1">{event.description}</div>
            </div>
          </div>
        ))}
        {events.length === 0 && (
          <div className="text-center text-gray-500 py-8">
            <p className="text-lg mb-2">No events recorded yet</p>
            <p className="text-sm">Add your first timeline event to get started</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseTimeline; 