import React from 'react';
import { Building2, Users, Calendar, Shield } from 'lucide-react';
import { useSelector } from 'react-redux';

const UserWorkspaceView = ({ workspace }) => {
  const { user } = useSelector((state) => state.auth);
  
  if (!workspace) return null;

  const userMember = workspace.members.find(
    member => member.user._id === user._id
  );

  const getRoleBadgeColor = (role) => {
    switch (role) {
      case 'TEAM_MEMBER':
        return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200';
      case 'CASE_MANAGER':
        return 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200';
      case 'ORGANIZATION_ADMIN':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200';
      case 'SUPER_ADMIN':
        return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
    }
  };

  const userRole = userMember?.role || workspace.role;

  return (
    <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg p-6">
      <div className="space-y-6">
        {/* Role Badge Section */}
        <div className="flex flex-col items-center justify-center p-6 bg-gray-50 dark:bg-gray-700 rounded-lg">
          <Shield className="h-8 w-8 text-blue-500 mb-2" />
          <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
            Your Role
          </h4>
          <span className={`px-4 py-2 rounded-full text-sm font-medium ${getRoleBadgeColor(userRole)}`}>
            {userRole || 'Unknown Role'}
          </span>
        </div>

        {/* Workspace Header */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
            Workspace Information
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Your current workspace details and team information
          </p>
        </div>

        {/* Workspace Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex items-center space-x-3 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <Building2 className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">Workspace</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">{workspace.name}</p>
            </div>
          </div>

          <div className="flex items-center space-x-3 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <Users className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">Team Size</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {workspace.members.length} members
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-3 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <Calendar className="h-5 w-5 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">Joined Date</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {new Date(workspace.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        {/* Workspace Description */}
        {workspace.description && (
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white mb-2">Description</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{workspace.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserWorkspaceView; 