import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import toast from 'react-hot-toast';

const AcceptInvitation = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const acceptInvitation = async () => {
      try {
        setIsLoading(true);
        console.log('Accepting invitation with token:', token); // Debug log

        const response = await api.post(`/api/workspaces/join/${token}`);
        
        if (response.data.success) {
          toast.success('Invitation accepted successfully');
          // Navigate to the workspace or dashboard
          navigate('/dashboard');
        } else {
          throw new Error(response.data.message || 'Failed to accept invitation');
        }
      } catch (error) {
        console.error('Accept invitation error:', error);
        setError(error.response?.data?.message || 'Failed to accept invitation');
        toast.error(error.response?.data?.message || 'Failed to accept invitation');
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      acceptInvitation();
    }
  }, [token, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-300">Processing your invitation...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-500 text-xl mb-4">⚠️</div>
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
            Failed to Accept Invitation
          </h2>
          <p className="text-gray-600 dark:text-gray-300">{error}</p>
          <button
            onClick={() => navigate('/dashboard')}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default AcceptInvitation; 