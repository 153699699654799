import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import api from '../services/api';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('verifying');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [resendStatus, setResendStatus] = useState('');
  const navigate = useNavigate();

  console.log('EmailVerification component rendered');

  useEffect(() => {
    console.log('EmailVerification useEffect triggered');
    const verifyEmail = async () => {
      const token = searchParams.get('token');
      console.log('Token:', token);

      if (!token) {
        setStatus('error');
        setMessage('Verification token is missing');
        return;
      }

      try {
        const response = await api.get(`/api/auth/verify-email?token=${token}`);
        setStatus('success');
        setMessage(response.data.message);
      } catch (error) {
        setStatus('error');
        const responseData = error.response?.data;
        setMessage(responseData?.message || 'Verification failed');
        
        // If token is expired, show resend option
        if (responseData?.expired) {
          setStatus('expired');
        }
      }
    };

    verifyEmail();
  }, [searchParams]);

  console.log('Current status:', status);
  console.log('Current message:', message);

  const handleResendVerification = async () => {
    if (!email) {
      setResendStatus('Please enter your email address');
      return;
    }

    try {
      const response = await api.post(`/api/auth/resend-verification`, { email });
      setResendStatus(response.data.message);
      setTimeout(() => {
        navigate('/login?verified=pending');
      }, 3000);
    } catch (error) {
      setResendStatus(error.response?.data?.message || 'Error resending verification email');
    }
  };

  return (
    <div className="bg-blue-900 min-h-screen bg-gradient-to-b from-blue-900 via-blue-800 to-blue-600 font-[Poppins]">
      <div className="relative min-h-screen sm:flex sm:flex-row justify-center bg-transparent rounded-3xl shadow-xl">
        <div className="flex justify-center self-center z-10">
          <div className="p-12 bg-white mx-auto rounded-3xl w-[450px] shadow-xl">
            {status === 'verifying' && (
              <div className="text-center">
                <div className="mb-4">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-900 mx-auto"></div>
                </div>
                <h3 className="text-xl font-semibold text-gray-800">Verifying your email...</h3>
                <p className="text-gray-600 mt-2">Please wait while we verify your email address.</p>
              </div>
            )}

            {status === 'success' && (
              <div className="text-center">
                <div className="mb-4">
                  <svg className="w-16 h-16 text-green-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-800">Email Verified Successfully!</h3>
                <p className="text-gray-600 mt-2">{message}</p>
                <Link
                  to="/login"
                  className="mt-6 inline-block bg-blue-600 text-white rounded-lg px-6 py-3 font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                >
                  Proceed to Login
                </Link>
              </div>
            )}

            {status === 'expired' && (
              <div className="text-center">
                <div className="mb-4">
                  <svg className="w-16 h-16 text-yellow-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-800">Verification Link Expired</h3>
                <p className="text-gray-600 mt-2">{message}</p>
                <div className="mt-6">
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    onClick={handleResendVerification}
                    className="mt-4 w-full bg-blue-600 text-white rounded-lg px-6 py-3 font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                  >
                    Resend Verification Email
                  </button>
                  {resendStatus && (
                    <p className="mt-3 text-sm text-gray-600">{resendStatus}</p>
                  )}
                </div>
              </div>
            )}

            {status === 'error' && !message.includes('expired') && (
              <div className="text-center">
                <div className="mb-4">
                  <svg className="w-16 h-16 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-gray-800">Verification Failed</h3>
                <p className="text-gray-600 mt-2">{message}</p>
                <Link
                  to="/signup"
                  className="mt-6 inline-block bg-blue-600 text-white rounded-lg px-6 py-3 font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
                >
                  Back to Sign Up
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <svg className="absolute bottom-0 left-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#fff" fillOpacity="1" d="M0,0L40,42.7C80,85,160,171,240,197.3C320,224,400,192,480,154.7C560,117,640,75,720,74.7C800,75,880,117,960,154.7C1040,192,1120,224,1200,213.3C1280,203,1360,149,1400,122.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" />
      </svg>
    </div>
  );
};

export default EmailVerification;