import React, { useState } from 'react';
import { FaPhone, FaEnvelope, FaCalendar, FaEdit, FaHistory } from 'react-icons/fa';
import CommunicationForm from './CommunicationForm';

const ClientInfo = ({ client, onUpdate }) => {
  const [showCommunicationForm, setShowCommunicationForm] = useState(false);
  const [communicationType, setCommunicationType] = useState(null);

  const handleAddCommunication = (type) => {
    setCommunicationType(type);
    setShowCommunicationForm(true);
  };

  const handleCommunicationSubmit = async (communicationData) => {
    try {
      // In production, this would be an API call
      const newCommunication = {
        id: Date.now().toString(),
        ...communicationData
      };

      // If it's an email, send it
      if (communicationData.type === 'email') {
        // Implement email sending logic
        console.log('Sending email:', communicationData);
      }

      // If it's a meeting, create calendar event
      if (communicationData.type === 'meeting') {
        // Implement calendar integration
        console.log('Creating calendar event:', communicationData);
      }

      // Update the client's communication history
      const updatedClient = {
        ...client,
        communications: [newCommunication, ...(client.communications || [])]
      };
      onUpdate(updatedClient);
    } catch (error) {
      console.error('Error adding communication:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Client Details Section */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-lg font-semibold">Client Information</h3>
          <button className="text-blue-600 hover:text-blue-700">
            <FaEdit className="w-5 h-5" />
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500">Full Name</p>
            <p className="font-medium">{client.name}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Email</p>
            <p className="font-medium">{client.email}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Phone</p>
            <p className="font-medium">{client.phone}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Address</p>
            <p className="font-medium">{client.address}</p>
          </div>
        </div>
      </div>

      {/* Communication Tools */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-semibold mb-4">Quick Actions</h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <button
            onClick={() => handleAddCommunication('call')}
            className="flex items-center justify-center gap-2 p-3 border rounded-lg hover:bg-gray-50"
          >
            <FaPhone className="text-blue-600" />
            <span>Log Call</span>
          </button>
          <button
            onClick={() => handleAddCommunication('email')}
            className="flex items-center justify-center gap-2 p-3 border rounded-lg hover:bg-gray-50"
          >
            <FaEnvelope className="text-blue-600" />
            <span>Send Email</span>
          </button>
          <button
            onClick={() => handleAddCommunication('meeting')}
            className="flex items-center justify-center gap-2 p-3 border rounded-lg hover:bg-gray-50"
          >
            <FaCalendar className="text-blue-600" />
            <span>Schedule Meeting</span>
          </button>
        </div>
      </div>

      {/* Communication History */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Communication History</h3>
          <button
            onClick={() => setShowCommunicationForm(true)}
            className="text-blue-600 hover:text-blue-700 flex items-center gap-2"
          >
            <FaHistory />
            <span>View All</span>
          </button>
        </div>
        
        <div className="space-y-4">
          {client.communications?.map((comm, index) => (
            <div key={index} className="border-l-4 border-blue-500 pl-4 py-2">
              <div className="flex justify-between items-start">
                <div>
                  <p className="font-medium">{comm.type}</p>
                  <p className="text-sm text-gray-600">{comm.description}</p>
                </div>
                <span className="text-sm text-gray-500">
                  {new Date(comm.timestamp).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Client Portal Section */}
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-semibold mb-4">Client Portal Access</h3>
        <div className="space-y-4">
          <div className="flex justify-between items-center p-4 bg-gray-50 rounded-lg">
            <div>
              <p className="font-medium">Portal Status</p>
              <p className="text-sm text-gray-600">
                {client.portalAccess ? 'Active' : 'Inactive'}
              </p>
            </div>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
              {client.portalAccess ? 'Manage Access' : 'Enable Access'}
            </button>
          </div>
          
          {client.portalAccess && (
            <div className="space-y-2">
              <p className="text-sm text-gray-600">Last Login: {client.lastLogin}</p>
              <p className="text-sm text-gray-600">
                Documents Accessed: {client.documentsAccessed || 0}
              </p>
            </div>
          )}
        </div>
      </div>

      {showCommunicationForm && (
        <CommunicationForm
          type={communicationType}
          client={client}
          onSubmit={handleCommunicationSubmit}
          onClose={() => {
            setShowCommunicationForm(false);
            setCommunicationType(null);
          }}
        />
      )}
    </div>
  );
};

export default ClientInfo; 