// src/config.js
const isDevelopment = process.env.REACT_APP_NODE_ENV !== 'production';

export const config = {
  API_URL: isDevelopment 
    ? 'http://localhost:5001'
    : process.env.REACT_APP_API_URL || 'https://modulawback-fqabdxbbdqgngwdn.eastus2-01.azurewebsites.net',
  FRONTEND_URL: isDevelopment
    ? 'http://localhost:3000'
    : process.env.REACT_APP_FRONTEND_URL || 'https://polite-hill-03002ae0f.4.azurestaticapps.net'
};