import React from 'react';

const Logo = ({ className = '', width = '150', height = '150' }) => {
  const isDarkMode = document.documentElement.classList.contains('dark');
  const logoSrc = isDarkMode ? '/Modulaw-Logo-dark.svg' : '/Modulaw-Logo-light.svg';

  return (
    <img
      src={logoSrc}
      alt="Modulaw AI Logo"
      className={`transition-all duration-200 ${className}`}
      width={width}
      height={height}
    />
  );
};

export default Logo; 