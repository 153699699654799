import React from 'react';
import { FileText, X } from 'lucide-react';

export const FilePreviewBadge = ({ file, onRemove }) => {
  if (!file) return null;

  return (
    <div className="absolute bottom-2 left-14 flex items-center bg-gray-100 dark:bg-gray-600 rounded-full px-3 py-1 max-w-[200px]">
      <FileText className="h-4 w-4 text-gray-600 dark:text-gray-300 mr-2" />
      <span className="text-xs text-gray-600 dark:text-gray-300 truncate">
        {file.name}
      </span>
      <button
        onClick={onRemove}
        className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
      >
        <X className="h-4 w-4" />
      </button>
    </div>
  );
};