import React from 'react';
import { Gavel } from 'lucide-react';

export const Avatar = ({ role, username }) => {
  if (role === 'user') {
    return (
      <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white text-sm font-medium relative overflow-hidden">
        <div className="animate-wave absolute inset-0 opacity-50" />
        <div className="animate-wave-delayed absolute inset-0 opacity-30" />
        <span className="z-10">{username?.[0]?.toUpperCase() || 'U'}</span>
      </div>
    );
  }

  return (
    <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center relative overflow-hidden">
      <div className="animate-wave absolute inset-0 opacity-50" />
      <div className="animate-wave-delayed absolute inset-0 opacity-30" />
      <Gavel className="w-5 h-5 text-white z-10" />
    </div>
  );
};