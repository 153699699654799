import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/gradient.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const legalIcons = [
  '⚖️', '📚', '📋', '🔨', '📜', '🏛️', '📝', '👨‍⚖️', '🗃️', '📔'
];

const FloatingIcon = ({ icon, delay }) => {
  const size = Math.random() * (2.5 - 1.5) + 1.5;
  const startPosition = Math.random() * 120 - 10;
  const rotation = Math.random() * 360;
  const duration = Math.random() * (20 - 12) + 12;

  return (
    <div 
      className="absolute animate-float opacity-15 dark:opacity-10 select-none"
      style={{
        left: `${startPosition}%`,
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        fontSize: `${size}rem`,
        transform: `rotate(${rotation}deg)`,
        willChange: 'transform',
      }}
    >
      {icon}
    </div>
  );
};

const FeatureCard = ({ feature, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-white dark:bg-gray-700 rounded-xl p-8 hover:bg-gray-50 dark:hover:bg-gray-600 
        transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1"
    >
      <div className="text-5xl mb-6">{feature.icon}</div>
      <h3 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
        {feature.title}
      </h3>
      <p className="text-gray-600 dark:text-gray-300">
        {feature.description}
      </p>
    </motion.div>
  );
};

const TestimonialCard = ({ testimonial, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-white dark:bg-gray-700 rounded-xl p-8 shadow-xl hover:shadow-2xl 
        transition-all duration-300 border border-gray-100 dark:border-gray-600"
    >
      <div className="flex items-center mb-6">
        <div className="relative">
          <img 
            src={testimonial.image} 
            alt={testimonial.name}
            className="w-16 h-16 rounded-full object-cover border-4 border-blue-100 dark:border-blue-900"
          />
          <div className="absolute -bottom-2 -right-2 bg-blue-500 rounded-full p-1">
            <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
        </div>
        <div className="ml-4">
          <h3 className="font-bold text-xl text-gray-900 dark:text-white">
            {testimonial.name}
          </h3>
          <p className="text-gray-600 dark:text-gray-300">
            {testimonial.title}
          </p>
        </div>
      </div>
      <p className="text-gray-600 dark:text-gray-300 italic leading-relaxed">
        "{testimonial.content}"
      </p>
    </motion.div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Comprehensive Legal Database",
      description: "Access Nigeria's largest digital library of court judgments, including Supreme Court, Court of Appeal, Federal High Court, and State High Court decisions.",
      icon: "📚"
    },
    {
      title: "AI-Powered Legal Research",
      description: "Leverage advanced AI to analyze case law, identify precedents, and extract key legal principles with unmatched accuracy and speed.",
      icon: "🤖"
    },
    {
      title: "Intelligent Document Analysis",
      description: "Upload legal documents for instant AI analysis, case citations, and relevant statutory provisions with our advanced legal analytics.",
      icon: "📄"
    },
    {
      title: "Practice Management Suite",
      description: "Streamline your legal practice with integrated case tracking, deadline management, and automated legal document generation.",
      icon: "⚖️"
    }
  ];

  const benefits = [
    "Reduce research time by up to 99.9%",
    "Never miss relevant precedents or citations",
    "Strengthen arguments with comprehensive legal analysis",
    "Stay updated with latest judicial pronouncements",
    "Automate routine legal documentation",
    "Enhance litigation strategy with AI insights"
  ];

  const testimonials = [
    {
      name: "Oluwaseun Adebayo",
      title: "Senior Partner, Adebayo & Associates",
      content: "ModulawAI transformed our litigation practice. We recently won a complex commercial case by discovering a precedent that traditional research had missed. The AI's ability to analyze Nigerian case law is remarkable.",
      image: "https://randomuser.me/api/portraits/men/1.jpg"
    },
    {
      name: "Chidinma Okonkwo",
      title: "Legal Researcher, Lagos State Ministry of Justice",
      content: "The depth of legal analysis is outstanding. We've reduced our research time from days to minutes, and the AI's ability to cross-reference statutes with relevant case law has significantly strengthened our legal opinions.",
      image: "https://randomuser.me/api/portraits/women/2.jpg"
    },
    {
      name: "Ibrahim Musa",
      title: "Corporate Counsel, First Bank Nigeria",
      content: "For corporate legal work, ModulawAI is invaluable. The document analysis feature has helped us review hundreds of contracts, identifying potential risks and relevant legal precedents that impact our agreements.",
      image: "https://randomuser.me/api/portraits/men/3.jpg"
    }
  ];

  const faqItems = [
    {
      question: "What makes ModulawAI different from traditional legal research tools?",
      answer: "ModulawAI combines Nigeria's largest digital legal database with advanced AI technology to provide instant, comprehensive legal analysis. Unlike traditional tools, our AI understands legal context, identifies relevant precedents across jurisdictions, and provides actionable insights for your cases. We offer features like instant case analysis, precedent matching, and automated legal document review that dramatically reduce research time while improving accuracy."
    },
    {
      question: "How accurate is ModulawAI's legal analysis?",
      answer: "ModulawAI is trained on millions of legal documents and validated by experienced Nigerian lawyers. Our AI achieves over 95% accuracy in case law analysis and citation matching. The system is continuously updated with the latest judicial decisions and statutory amendments, ensuring you always have access to current legal information. However, we recommend using it as a powerful complement to your professional expertise."
    },
    {
      question: "What subscription plans do you offer?",
      answer: "We offer flexible subscription plans including monthly and annual options, with different tiers for individual practitioners, law firms, and organizations. Any user can start with a 3-day free trial, allowing you to experience the full platform before committing."
    },
    {
      question: "How do you keep legal information up-to-date?",
      answer: "Our database is continuously updated with the latest court decisions, legislation changes, and legal developments. We maintain direct partnerships with courts and legal institutions to ensure timely updates to our knowledge base."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, we implement bank-grade security measures including end-to-end encryption for all communications. Your client information and legal documents are stored securely and never shared with third parties."
    },
    {
      question: "Can I upload my own documents for analysis?",
      answer: "Yes, you can upload legal documents in various formats (PDF, DOC, DOCX) for AI-powered analysis. The system will analyze the content and provide relevant insights, citations, and cross-references."
    },
    {
      question: "Do you offer training and support?",
      answer: "Yes, we provide comprehensive onboarding training, video tutorials, and dedicated customer support. Our support team is available during business hours to assist with any questions or technical issues."
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Yes, you can cancel your subscription at any time through your account dashboard. For annual subscriptions, we offer prorated refunds for the unused portion of your service."
    },
    {
      question: "How does ModulawAI handle legal document analysis?",
      answer: "Our AI can analyze various legal documents including contracts, pleadings, and judgments. It automatically identifies key legal issues, extracts relevant citations, cross-references applicable statutes, and suggests relevant precedents. This helps you quickly understand document implications and strengthen your legal position."
    },
    {
      question: "Can ModulawAI assist with litigation strategy?",
      answer: "Yes, ModulawAI provides strategic insights by analyzing similar cases, identifying successful arguments, and highlighting key factors that influenced judicial decisions. It helps you build stronger cases by finding relevant precedents and understanding how courts have ruled on similar issues."
    }
  ];

  return (
    <div className="min-h-screen">
      <section className="relative min-h-[85vh] overflow-hidden flex items-center">
        <div className="gradient-animation absolute inset-0">
          {/* Floating Icons */}
          {[...Array(2)].map((_, i) => (
            <React.Fragment key={i}>
              {legalIcons.map((icon, index) => (
                <FloatingIcon 
                  key={`${i}-${index}`} 
                  icon={icon} 
                  delay={Math.random() * 10}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
        
        <div className="mesh-overlay"></div>
        
        <div className="relative z-10 container mx-auto px-4 flex flex-col justify-center items-center text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl sm:text-6xl lg:text-7xl font-bold text-white mb-8 leading-tight">
              Perform faster & smarter<br />Legal research with{' '}
              <span className="text-blue-600 [text-shadow:_-1px_-1px_0_#fff,_1px_-1px_0_#fff,_-1px_1px_0_#fff,_1px_1px_0_#fff]">
                AI
              </span>
            </h1>
            <p className="text-xl sm:text-2xl text-white/90 mb-12 max-w-3xl mx-auto leading-relaxed">
              Access 100,000+ Nigerian court judgments, statutes, and precedents. Get instant legal insights powered by ModulawAI for superior legal research and litigation success.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6">
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/signup')}
                className="bg-blue-600 hover:bg-blue-700 text-white px-10 py-4 rounded-xl 
                  text-lg font-semibold transition-all duration-200 shadow-lg hover:shadow-xl
                  border-2 border-white"
              >
                Start Free Trial
              </motion.button>
              <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/contact')}
                className="bg-white/10 backdrop-blur-sm border-2 border-white/30 text-white 
                  hover:bg-white/20 px-10 py-4 rounded-xl text-lg font-semibold transition-all duration-200"
              >
                Schedule Demo
              </motion.button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* See it in Action Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 transition-colors duration-200">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Don't Take Our Word For It
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Watch ModulawAI in action as it transforms complex legal research into instant insights
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="relative rounded-2xl overflow-hidden shadow-2xl"
          >
            {/* Feature Highlights Above Video */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="flex items-start space-x-3">
                <div className="bg-blue-100 dark:bg-blue-900/50 rounded-full p-2">
                  <svg className="w-6 h-6 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900 dark:text-white">Lightning Fast</h3>
                  <p className="text-gray-600 dark:text-gray-300 text-sm">Get instant answers to complex legal queries</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <div className="bg-blue-100 dark:bg-blue-900/50 rounded-full p-2">
                  <svg className="w-6 h-6 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900 dark:text-white">Highly Accurate</h3>
                  <p className="text-gray-600 dark:text-gray-300 text-sm">Precise legal analysis and citations</p>
                </div>
              </div>

              <div className="flex items-start space-x-3">
                <div className="bg-blue-100 dark:bg-blue-900/50 rounded-full p-2">
                  <svg className="w-6 h-6 text-blue-600 dark:text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                  </svg>
                </div>
                <div>
                  <h3 className="font-semibold text-gray-900 dark:text-white">Comprehensive</h3>
                  <p className="text-gray-600 dark:text-gray-300 text-sm">Access to extensive legal resources</p>
                </div>
              </div>
            </div>

            {/* Video Section */}
            <div className="aspect-video relative">
              <iframe
                id="vimeoPlayer"
                src="https://player.vimeo.com/video/1034206511?h=7c51122a92&autoplay=0&title=0&byline=0&portrait=0"
                className="w-full h-full absolute inset-0 rounded-2xl"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
              
              {/* Custom Play Button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={(e) => {
                  // Get the iframe element
                  const iframe = document.getElementById('vimeoPlayer');
                  // Post message to Vimeo player to play the video
                  iframe.contentWindow.postMessage('{"method":"play"}', '*');
                  // Hide the play button after clicking
                  const playButton = e.currentTarget;
                  playButton.style.display = 'none';
                }}
                className="absolute inset-0 flex items-center justify-center z-10 bg-black/10 hover:bg-black/20 transition-colors duration-200 rounded-2xl group"
              >
                <div className="w-20 h-20 bg-white/90 hover:bg-white rounded-full flex items-center justify-center shadow-xl transition-all duration-200 group-hover:scale-110">
                  <svg 
                    className="w-10 h-10 text-blue-600 translate-x-0.5" 
                    fill="currentColor" 
                    viewBox="0 0 20 20"
                  >
                    <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                  </svg>
                </div>
              </motion.button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Time Comparison Section - Blue */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-blue-700 dark:bg-gray-800 transition-colors duration-200">
        <div className="max-w-7xl mx-auto">
          <motion.div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">
              Transform Your Legal Research
            </h2>
            <p className="text-gray-100 max-w-3xl mx-auto">
              See how ModulawAI compares to traditional methods and other legal research platforms
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="overflow-x-auto rounded-xl shadow-lg"
          >
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="p-4 text-left bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white rounded-tl-lg border-b border-gray-200 dark:border-gray-600">
                    Research Task
                  </th>
                  <th className="p-4 text-center bg-gray-50 dark:bg-gray-700 text-red-600 dark:text-red-400 border-b border-gray-200 dark:border-gray-600">
                    Traditional Method
                  </th>
                  <th className="p-4 text-center bg-gray-50 dark:bg-gray-700 text-orange-600 dark:text-orange-400 border-b border-gray-200 dark:border-gray-600">
                    Other Legal Tech
                  </th>
                  <th className="p-4 text-center bg-green-50 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-tr-lg border-b border-gray-200 dark:border-gray-600">
                    With ModulawAI
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-150">
                  <td className="p-4 text-white">
                    Finding relevant case law
                  </td>
                  <td className="p-4 text-center text-white">
                    2-3 days
                  </td>
                  <td className="p-4 text-center text-white">
                    30-45 minutes
                  </td>
                  <td className="p-4 text-center text-green-600 dark:text-green-400 font-semibold">
                    &lt; 5 seconds
                  </td>
                </tr>
                <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-150">
                  <td className="p-4 text-white">
                    Cross-referencing statutes
                  </td>
                  <td className="p-4 text-center text-white">
                    4-6 hours
                  </td>
                  <td className="p-4 text-center text-white">
                    15-20 minutes
                  </td>
                  <td className="p-4 text-center text-green-600 dark:text-green-400 font-semibold">
                    &lt; 3 seconds
                  </td>
                </tr>
                <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-150">
                  <td className="p-4 text-white">
                    Analyzing legal documents
                  </td>
                  <td className="p-4 text-center text-white">
                    5-8 hours
                  </td>
                  <td className="p-4 text-center text-white">
                    1-2 hours
                  </td>
                  <td className="p-4 text-center text-green-600 dark:text-green-400 font-semibold">
                    &lt; 8 seconds
                  </td>
                </tr>
                <tr className="hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors duration-150">
                  <td className="p-4 text-white">
                    Legal precedent research
                  </td>
                  <td className="p-4 text-center text-white">
                    1-2 weeks
                  </td>
                  <td className="p-4 text-center text-white">
                    2-3 hours
                  </td>
                  <td className="p-4 text-center text-green-600 dark:text-green-400 font-semibold">
                    &lt; 7 seconds
                  </td>
                </tr>
                <tr>
                  <td className="p-4 text-white rounded-bl-lg">
                    Drafting legal summaries
                  </td>
                  <td className="p-4 text-center text-white">
                    3-4 hours
                  </td>
                  <td className="p-4 text-center text-white">
                    45-60 minutes
                  </td>
                  <td className="p-4 text-center text-green-600 dark:text-green-400 font-semibold rounded-br-lg">
                    &lt; 20 seconds
                  </td>
                </tr>
              </tbody>
            </table>
          </motion.div>

          {/* Impact Summary */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="mt-12 text-center"
          >
            <div className="inline-flex items-center justify-center space-x-2 mb-6">
              <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              <p className="text-2xl font-bold text-white">
                Save up to <span className="text-blue-300">99.9%</span> of your research time
              </p>
            </div>
            <p className="text-white/90 max-w-2xl mx-auto">
              Transform weeks of research into seconds. ModulawAI's advanced AI technology understands your legal queries and instantly provides relevant cases, statutes, and insights.
            </p>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/signup')}
              className="mt-8 px-8 py-3 bg-gray-100 hover:bg-gray-200 text-blue-700 rounded-lg transition-colors duration-200 shadow-lg hover:shadow-xl"
            >
              Start Your Free Trial
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* Features Section - White (keep as is) */}
      <section className="py-24 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-4xl font-bold text-gray-900 dark:text-white mb-4"
            >
              Powerful Features for Modern Legal Practice
            </motion.h2>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Everything you need to streamline your legal research and case management
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features
              .filter(feature => 
                !feature.title.includes('Practice Management Suite')
              )
              .map((feature, index) => (
                <FeatureCard key={index} feature={feature} index={index} />
              ))
            }
          </div>
        </div>
      </section>

      {/* Testimonials Section - Blue */}
      <section className="py-24 px-4 sm:px-6 lg:px-8 bg-blue-700 dark:bg-gray-800">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-white mb-4">
              Trusted by Legal Professionals
            </h2>
            <p className="text-gray-100">
              Join hundreds of satisfied legal practitioners using ModulawAI
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard key={index} testimonial={testimonial} index={index} />
            ))}
          </div>
        </div>
      </section>

      {/* Data Security Section - White (keep as is) */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Enterprise-Grade Security
            </h2>
            <p className="text-xl text-gray-600 dark:text-gray-400 max-w-3xl mx-auto">
              Your data security is our top priority. ModulawAI implements bank-level security measures to protect your sensitive legal information.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-gray-50 dark:bg-gray-700 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/10 dark:hover:shadow-gray-500/5"
            >
              <div className="bg-blue-500/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">End-to-End Encryption</h3>
              <p className="text-gray-600 dark:text-gray-300">
                All data is encrypted in transit and at rest using industry-standard AES-256 encryption.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-gray-50 dark:bg-gray-700 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/10 dark:hover:shadow-gray-500/5"
            >
              <div className="bg-blue-500/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">ISO 27001 Certified</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Our cloud infrastructure meets international security standards for information security management.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-gray-50 dark:bg-gray-700 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/10 dark:hover:shadow-gray-500/5"
            >
              <div className="bg-blue-500/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Automated Backups</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Regular automated backups with geo-redundancy ensure your data is always safe and accessible.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Case Manager Suite Section - Blue */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-blue-700 dark:bg-gray-800">
        <div className="max-w-7xl mx-auto">
          <motion.div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">
              All-in-One Legal Practice Suite
            </h2>
            <p className="text-gray-100 max-w-3xl mx-auto">
              ModulawAI's Case Manager is a comprehensive solution designed to streamline every aspect of your legal practice.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/20 dark:hover:shadow-gray-500/10"
            >
              <div className="text-4xl mb-4">📅</div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Smart Calendar Management</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                <li>• Automated court date tracking</li>
                <li>• Deadline reminders and alerts</li>
                <li>• Integration with popular calendar apps</li>
                <li>• Team scheduling coordination</li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/20 dark:hover:shadow-gray-500/10"
            >
              <div className="text-4xl mb-4">📋</div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Document Automation</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                <li>• Intelligent document generation</li>
                <li>• Template management system</li>
                <li>• Automated form filling</li>
                <li>• Version control and tracking</li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow 
                shadow-blue-500/20 hover:shadow-blue-500/10 dark:shadow-gray-500/20 dark:hover:shadow-gray-500/10"
            >
              <div className="text-4xl mb-4">📊</div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Analytics Dashboard</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-300">
                <li>• Case performance metrics</li>
                <li>• Resource utilization tracking</li>
                <li>• Financial reporting</li>
                <li>• Productivity analytics</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* FAQ Section - White (keep as is) */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col items-center mb-12">
            <h2 className="font-bold text-3xl md:text-4xl text-center text-gray-900 dark:text-white">
              Frequently Asked Questions
            </h2>
            <p className="text-neutral-500 dark:text-neutral-400 text-xl mt-3">
              Everything you need to know about ModulawAI
            </p>
          </div>
          
          <div className="grid divide-y divide-neutral-200 dark:divide-neutral-700 mx-auto mt-8">
            {faqItems.map((item, index) => (
              <div key={index} className="py-5">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                    <span className="text-gray-900 dark:text-white">{item.question}</span>
                    <span className="transition group-open:rotate-180">
                      <svg
                        fill="none"
                        height="24"
                        shapeRendering="geometricPrecision"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                        className="text-gray-700 dark:text-gray-300"
                      >
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p className="text-neutral-600 dark:text-neutral-300 mt-3 group-open:animate-fadeIn">
                    {item.answer}
                  </p>
                </details>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-blue-700 dark:bg-blue-800 transition-colors duration-200">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-white">
            Join Nigeria's Leading Law Firms Using ModulawAI
          </h2>
          <p className="text-lg mb-8 text-gray-100">
            Transform your legal practice with AI-powered research, analysis, and insights. Start your free trial today and experience the future of legal technology.
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/signup')}
            className="bg-white text-blue-700 hover:bg-gray-100 dark:hover:bg-gray-200 px-8 py-3 rounded-lg text-lg transition-colors duration-200 shadow-lg hover:shadow-xl"
          >
            Start Your Free Trial Now
          </motion.button>
          <p className="text-sm text-gray-100 mt-4">
            No credit card required • Full access for 3 days • Cancel anytime
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;