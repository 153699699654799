import React, { useState } from 'react';
import { Upload, X } from 'lucide-react';
import api from '../../services/api';
import toast from 'react-hot-toast';

const WORKSPACE_ROLES = {
  ORGANIZATION_ADMIN: 'Organization Admin',
  CASE_MANAGER: 'Case Manager',
  TEAM_MEMBER: 'Team Member',
  CLIENT: 'Client',
  AUDITOR: 'Auditor'
};

const InvitationModal = ({ isOpen, onClose, workspace, onInvitationComplete }) => {
  const [inviteType, setInviteType] = useState('single');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('TEAM_MEMBER');
  const [isLoading, setIsLoading] = useState(false);

  const handleSingleInvite = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter an email address');
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post(`/api/workspaces/${workspace._id}/invite`, {
        email,
        role
      });

      if (response.data.success) {
        toast.success('Invitation sent successfully');
        setEmail('');
        onInvitationComplete?.();
        onClose();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to send invitation');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBulkImport = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
      toast.error('Please upload a CSV file');
      return;
    }

    setIsLoading(true);
    try {
      const text = await file.text();
      const members = parseCSV(text);

      if (members.length === 0) {
        throw new Error('No valid members found in CSV');
      }

      const response = await api.post(`/api/workspaces/${workspace._id}/bulk-invite`, {
        members
      });

      if (response.data.success) {
        toast.success(`Successfully processed ${members.length} invitations`);
        onInvitationComplete?.();
        onClose();
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to process CSV');
    } finally {
      setIsLoading(false);
      event.target.value = '';
    }
  };

  const parseCSV = (text) => {
    const rows = text.split('\n');
    return rows.slice(1)
      .filter(row => row.trim())
      .map(row => {
        const [email, role] = row.split(',').map(v => v.trim());
        return { email, role: role?.toUpperCase() };
      })
      .filter(member => 
        member.email && 
        member.role && 
        Object.keys(WORKSPACE_ROLES).includes(member.role)
      );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
        
        <div className="relative bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6 shadow-xl">
          <div className="absolute top-4 right-4">
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>

          <h2 className="text-xl font-semibold mb-6 text-gray-900 dark:text-white">
            Invite Members
          </h2>

          <div className="mb-6">
            <div className="flex space-x-4">
              <button
                onClick={() => setInviteType('single')}
                className={`flex-1 py-2 px-4 rounded-md ${
                  inviteType === 'single'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
                }`}
              >
                Single Invite
              </button>
              <button
                onClick={() => setInviteType('bulk')}
                className={`flex-1 py-2 px-4 rounded-md ${
                  inviteType === 'bulk'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
                }`}
              >
                Bulk Import
              </button>
            </div>
          </div>

          {inviteType === 'single' ? (
            <form onSubmit={handleSingleInvite} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email Address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
                  placeholder="member@example.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Role
                </label>
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white sm:text-sm"
                >
                  {Object.entries(WORKSPACE_ROLES).map(([value, label]) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </select>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? 'Sending...' : 'Send Invitation'}
              </button>
            </form>
          ) : (
            <div className="text-center">
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Upload CSV File
                </label>
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleBulkImport}
                  disabled={isLoading}
                  className="mt-2 block w-full text-sm text-gray-500 dark:text-gray-400
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-md file:border-0
                    file:text-sm file:font-medium
                    file:bg-blue-600 file:text-white
                    hover:file:bg-blue-700"
                />
                <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                  Format: email, role<br />
                  Available roles: {Object.keys(WORKSPACE_ROLES).join(', ')}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvitationModal; 