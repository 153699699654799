import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, Send, Clock, ChevronDown } from 'lucide-react';
import { toast } from 'react-hot-toast';
import WorkspaceMembers from './WorkspaceMembers';
import api from '../../services/api';

const WorkspaceCard = ({
  workspace,
  editingWorkspace,
  editWorkspaceName,
  editWorkspaceDescription,
  setEditWorkspaceName,
  setEditWorkspaceDescription,
  handleSaveWorkspaceEdit,
  setEditingWorkspace,
  handleEditWorkspace,
  handleDeleteWorkspace,
  handleRoleChange,
  handleCreditChange,
  handleRemoveMember,
  isLoading,
  selectedWorkspaceId,
  onInviteClick
}) => {
  const [pendingInvites, setPendingInvites] = useState([]);
  const [isLoadingInvites, setIsLoadingInvites] = useState(false);
  const [displayCount, setDisplayCount] = useState(5);
  const [totalInvites, setTotalInvites] = useState(0);

  const fetchPendingInvites = async () => {
    if (!workspace?._id) return;
    
    setIsLoadingInvites(true);
    try {
      const response = await api.get(`/api/workspaces/${workspace._id}/invitations`);
      if (response.data.success) {
        setPendingInvites(response.data.data.invitations);
        setTotalInvites(response.data.data.invitations.length);
      }
    } catch (error) {
      console.error('Error fetching pending invites:', error);
    } finally {
      setIsLoadingInvites(false);
    }
  };

  const handleLoadMore = () => {
    setDisplayCount(prev => prev + 5);
  };

  const handleCancelInvite = async (inviteId, inviteeEmail) => {
    try {
      const response = await api.delete(`/api/workspaces/invitations/${inviteId}`);
      if (response.data.success) {
        toast.success(`Invitation to ${inviteeEmail} cancelled successfully`);
        fetchPendingInvites();
      }
    } catch (error) {
      console.error('Error canceling invite:', error);
      toast.error(error.response?.data?.message || 'Failed to cancel invitation');
    }
  };

  useEffect(() => {
    fetchPendingInvites();
  }, [workspace?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!workspace) return null;

  const isOwnerOrAdmin = workspace.members?.some(member => 
    member.user._id === workspace.owner._id || 
    ['SUPER_ADMIN', 'ORGANIZATION_ADMIN'].includes(member.role)
  );

  const renderPendingInvites = () => {
    if (isLoadingInvites) {
      return <div className="text-sm text-gray-500">Loading invites...</div>;
    }

    if (pendingInvites.length === 0) {
      return <div className="text-sm text-gray-500">No pending invites</div>;
    }

    const displayedInvites = pendingInvites.slice(0, displayCount);
    const hasMore = pendingInvites.length > displayCount;

    return (
      <div className="space-y-2">
        {displayedInvites.map((invite) => (
          <div 
            key={invite._id} 
            className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-800 rounded-md"
          >
            <div className="flex items-center space-x-3">
              <Clock className="h-4 w-4 text-yellow-500" />
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {invite.email}
                </p>
                <p className="text-xs text-gray-500">
                  Invited by: {invite.inviterId ? 
                    `${invite.inviterId.firstName} ${invite.inviterId.lastName}` : 
                    'Unknown user'}
                </p>
                <p className="text-xs text-gray-500">
                  Role: {invite.role}
                </p>
                <p className="text-xs text-gray-500">
                  Expires: {new Date(invite.expiresAt).toLocaleDateString()}
                </p>
              </div>
            </div>
            {isOwnerOrAdmin && (
              <button
                onClick={() => handleCancelInvite(invite._id, invite.email)}
                className="text-red-600 hover:text-red-900 text-sm font-medium"
              >
                Cancel
              </button>
            )}
          </div>
        ))}
        
        {hasMore && (
          <button
            onClick={handleLoadMore}
            className="w-full mt-2 flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-700"
          >
            <ChevronDown className="h-4 w-4 mr-2" />
            Load More ({pendingInvites.length - displayCount} remaining)
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 hover:border-blue-500 dark:hover:border-blue-500 transition-colors duration-200">
      {/* Workspace Header */}
      <div className="p-4">
        {editingWorkspace === workspace._id ? (
          <WorkspaceEditForm
            editWorkspaceName={editWorkspaceName}
            editWorkspaceDescription={editWorkspaceDescription}
            setEditWorkspaceName={setEditWorkspaceName}
            setEditWorkspaceDescription={setEditWorkspaceDescription}
            handleSaveWorkspaceEdit={() => handleSaveWorkspaceEdit(workspace._id)}
            setEditingWorkspace={setEditingWorkspace}
          />
        ) : (
          <WorkspaceHeader
            workspace={workspace}
            handleEditWorkspace={isOwnerOrAdmin ? handleEditWorkspace : null}
            handleDeleteWorkspace={isOwnerOrAdmin ? handleDeleteWorkspace : null}
            onInviteClick={isOwnerOrAdmin ? onInviteClick : null}
          />
        )}
      </div>

      {/* Members Section */}
      <WorkspaceMembers
        workspace={workspace}
        handleRoleChange={handleRoleChange}
        handleCreditChange={handleCreditChange}
        handleRemoveMember={handleRemoveMember}
      />

      {/* Pending Invites Section */}
      <div className="border-t border-gray-200 dark:border-gray-600 mt-4">
        <div className="px-4 py-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              Pending Invites
            </h3>
            {totalInvites > 0 && (
              <span className="text-sm text-gray-500">
                {totalInvites} total
              </span>
            )}
          </div>
          {renderPendingInvites()}
        </div>
      </div>
    </div>
  );
};

const WorkspaceHeader = ({ 
  workspace, 
  handleEditWorkspace, 
  handleDeleteWorkspace,
  onInviteClick
}) => (
  <div className="flex justify-between items-start">
    <div>
      <h4 className="font-medium text-gray-900 dark:text-white">
        {workspace.name}
      </h4>
      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
        {workspace.description || 'No description'}
      </p>
    </div>
    <div className="flex items-center space-x-4">
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
        {workspace.members?.length || 1} member{workspace.members?.length !== 1 ? 's' : ''}
      </span>
      <button
        onClick={onInviteClick}
        className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <Send className="h-4 w-4 mr-1.5" />
        Invite
      </button>
      <div className="flex items-center space-x-1">
        <button
          onClick={() => handleEditWorkspace(workspace)}
          className="p-1 text-gray-500 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400"
          title="Edit workspace"
        >
          <Edit2 className="w-4 h-4" />
        </button>
        <button
          onClick={() => handleDeleteWorkspace(workspace._id)}
          className="p-1 text-gray-500 hover:text-red-600 dark:text-gray-400 dark:hover:text-red-400"
          title="Delete workspace"
        >
          <Trash2 className="w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
);

const WorkspaceEditForm = ({
  editWorkspaceName,
  editWorkspaceDescription,
  setEditWorkspaceName,
  setEditWorkspaceDescription,
  handleSaveWorkspaceEdit,
  setEditingWorkspace
}) => (
  <div className="space-y-3">
    <input
      type="text"
      value={editWorkspaceName}
      onChange={(e) => setEditWorkspaceName(e.target.value)}
      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      placeholder="Workspace name"
    />
    <textarea
      value={editWorkspaceDescription}
      onChange={(e) => setEditWorkspaceDescription(e.target.value)}
      className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      placeholder="Description"
      rows={2}
    />
    <div className="flex space-x-2">
      <button
        onClick={handleSaveWorkspaceEdit}
        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Save
      </button>
      <button
        onClick={() => setEditingWorkspace(null)}
        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-600 dark:text-gray-200 dark:border-gray-500 dark:hover:bg-gray-700"
      >
        Cancel
      </button>
    </div>
  </div>
);

export default WorkspaceCard; 