import React from 'react';
import { Crown, CreditCard, Plus, Building2, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CurrentPlan = ({ subscriptionData, onUpgrade, credits, creditsExpiry }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { workspaces } = useSelector((state) => state.workspace) || { workspaces: [] };

  const userWorkspace = workspaces[0]; // Assuming first workspace is the primary one
  const isSuperAdmin = userWorkspace?.members?.some(
    member => member.user._id === user._id && member.role === 'SUPER_ADMIN'
  );

  if (!subscriptionData) return null;

  // Show workspace-managed subscription view for non-super-admin workspace members
  if (userWorkspace && !isSuperAdmin) {
    return (
      <div className="max-w-3xl mx-auto">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <div className="mb-8">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Workspace Subscription
            </h3>
            <div className="p-6 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <div className="flex items-center justify-center mb-6">
                <Building2 className="h-12 w-12 text-blue-500" />
              </div>
              <div className="text-center mb-6">
                <p className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  {userWorkspace.name}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Your subscription is managed by your workspace
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
                  <div className="flex items-center space-x-3">
                    <Users className="h-5 w-5 text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        Workspace Members
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {userWorkspace.members.length} members
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
                  <div className="flex items-center space-x-3">
                    <Crown className="h-5 w-5 text-yellow-500" />
                    <div>
                      <p className="text-sm font-medium text-gray-900 dark:text-white">
                        Workspace Plan
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {subscriptionData.type}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 text-center">
                <p className="text-xs text-gray-400 dark:text-gray-500">
                  Contact your workspace administrator for subscription-related inquiries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Show regular subscription view for super admins and non-workspace users
  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            Current Plan
          </h3>
          <div className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <div className="flex items-center space-x-3">
              <Crown className="h-8 w-8 text-yellow-500" />
              <div>
                <p className="text-lg font-medium text-gray-900 dark:text-white">
                  {subscriptionData.type} Plan
                </p>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {credits?.toLocaleString()} credits remaining
                  </p>
                  {creditsExpiry && (
                    <p className="text-xs text-gray-400 dark:text-gray-500">
                      Expires: {new Date(creditsExpiry).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                onClick={onUpgrade}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <CreditCard className="w-4 h-4 mr-2" />
                Upgrade
              </button>
              <button
                onClick={() => navigate('/purchase-credits')}
                className="inline-flex items-center px-3 py-2 border border-blue-500 text-sm font-medium rounded-md text-blue-500 hover:bg-blue-50 dark:hover:bg-blue-900/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus className="w-4 h-4 mr-2" />
                Purchase
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;