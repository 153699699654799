import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Twitter, Github, Youtube } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Footer Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo Column */}
          <div>
            <Link to="/" className="text-2xl font-bold text-white">
              ModulawAI
            </Link>
          </div>

          {/* Solutions */}
          <div>
            <h3 className="text-white font-semibold mb-4">Solutions</h3>
            <ul className="space-y-2">
              <li><Link to="/pricing" className="hover:text-white">Pricing</Link></li>
              <li><Link to="/chat" className="hover:text-white">Chat</Link></li>
            </ul>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-white font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li><Link to="/documentation" className="hover:text-white">Documentation</Link></li>
              <li><Link to="/guides" className="hover:text-white">Guides</Link></li>
              <li><a href="/contact" className="hover:text-white">Contact</a></li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="hover:text-white">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-white">Terms of Service</Link></li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">© {new Date().getFullYear()} ModulawAI. All rights reserved.</p>
          
          {/* Social Links */}
          <div className="flex space-x-6 mt-4 md:mt-0">
            <a href="https://facebook.com" className="hover:text-white" aria-label="Facebook">
              <Facebook size={20} />
            </a>
            <a href="https://instagram.com" className="hover:text-white" aria-label="Instagram">
              <Instagram size={20} />
            </a>
            <a href="https://twitter.com" className="hover:text-white" aria-label="Twitter">
              <Twitter size={20} />
            </a>
            <a href="https://github.com" className="hover:text-white" aria-label="GitHub">
              <Github size={20} />
            </a>
            <a href="https://youtube.com" className="hover:text-white" aria-label="YouTube">
              <Youtube size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 