import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownMessage = ({ content, isDarkMode }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    className="markdown-content text-base text-gray-900 dark:text-white space-y-4"
    components={{
      // Headings with improved spacing
      h1: ({ children }) => (
        <h1 className="text-2xl font-bold mt-6 mb-4 text-gray-900 dark:text-white">
          {children}
        </h1>
      ),
      h2: ({ children }) => (
        <h2 className="text-xl font-semibold mt-5 mb-3 text-gray-900 dark:text-white">
          {children}
        </h2>
      ),
      h3: ({ children }) => (
        <h3 className="text-lg font-semibold mt-4 mb-2 text-gray-900 dark:text-white">
          {children}
        </h3>
      ),
      // Paragraphs with better spacing
      p: ({ children }) => (
        <p className="my-3 leading-relaxed text-gray-900 dark:text-white">
          {children}
        </p>
      ),
      // Lists with improved spacing
      ol: ({ children }) => (
        <ol className="list-decimal ml-6 my-4 space-y-2">
          {children}
        </ol>
      ),
      ul: ({ children }) => (
        <ul className="list-disc ml-6 my-4 space-y-2">
          {children}
        </ul>
      ),
      li: ({ children }) => (
        <li className="leading-relaxed text-gray-900 dark:text-white pl-2">
          {children}
        </li>
      ),
      // Blockquotes with better spacing
      blockquote: ({ children }) => (
        <blockquote className="border-l-4 border-gray-200 dark:border-gray-700 pl-4 my-4 italic">
          {children}
        </blockquote>
      ),
      // Code blocks with better spacing
      code: ({ node, inline, className, children, ...props }) => {
        const match = /language-(\w+)/.exec(className || '');
        const language = match ? match[1] : '';

        return inline ? (
          <code className="px-1.5 py-0.5 mx-1 rounded-md bg-gray-100 dark:bg-gray-800 text-sm font-mono">
            {children}
          </code>
        ) : (
          <div className="my-6">
            <SyntaxHighlighter
              language={language}
              style={vscDarkPlus}
              className="rounded-lg"
              customStyle={{
                padding: '1rem',
                borderRadius: '0.5rem',
              }}
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          </div>
        );
      },
      // Tables with better spacing
      table: ({ children }) => (
        <div className="overflow-x-auto my-6">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            {children}
          </table>
        </div>
      ),
      thead: ({ children }) => (
        <thead className="bg-gray-50 dark:bg-gray-800">{children}</thead>
      ),
      th: ({ children }) => (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
          {children}
        </th>
      ),
      td: ({ children }) => (
        <td className="px-6 py-4 whitespace-nowrap text-sm">
          {children}
        </td>
      ),
      // Emphasis
      strong: ({ children }) => (
        <strong className="font-semibold">{children}</strong>
      ),
      em: ({ children }) => (
        <em className="italic">{children}</em>
      ),
      // Links
      a: ({ children, href }) => (
        <a 
          href={href}
          className="text-blue-600 dark:text-blue-400 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ),
    }}
  >
    {content}
  </ReactMarkdown>
);

export default MarkdownMessage; 