import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileSuccess, logout } from '../store/authSlice';
import { toast } from 'react-toastify';
import api from '../services/api';

const PaymentCallback = () => {
  const [status, setStatus] = useState('processing');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get('reference');

      if (!reference) {
        setStatus('failed');
        return;
      }

      try {
        const response = await api.get(
          `/api/payments/verify/${reference}`
        );

        if (response.data.success) {
          dispatch(updateProfileSuccess({ 
            plan: {
              type: response.data.plan.type,
              status: 'active',
              billingCycle: response.data.plan.billingCycle || 'monthly',
              expiresAt: response.data.plan.expiresAt
            },
            credits: {
              balance: response.data.credits,
              expiresAt: response.data.creditsExpires
            },
            creditsExpires: response.data.creditsExpires
          }));
          
          setStatus('success');
          
          toast.success('Subscription updated successfully!');
          
          setTimeout(() => navigate('/chat'), 2000);
        } else {
          setStatus('failed');
          toast.error('Payment verification failed');
        }
      } catch (error) {
        console.error('Payment verification failed:', error);
        if (error.response?.status === 401) {
          dispatch(logout());
          navigate('/login', { 
            state: { 
              from: location.pathname,
              message: 'Your session has expired. Please log in again to verify your payment.'
            }
          });
        } else {
          setStatus('failed');
          toast.error(error.response?.data?.error || 'Payment verification failed');
        }
      }
    };

    if (!token) {
      dispatch(logout());
      navigate('/login', { 
        state: { 
          from: location.pathname,
          message: 'Please log in to verify your payment.'
        }
      });
      return;
    }

    verifyPayment();
  }, [location, navigate, dispatch, token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        {status === 'processing' && (
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-lg">Verifying your payment...</p>
          </div>
        )}
        
        {status === 'success' && (
          <div className="text-center text-green-600">
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <h2 className="mt-4 text-xl font-bold">Payment Successful!</h2>
            <p className="mt-2">Redirecting you back to the chat...</p>
          </div>
        )}
        
        {status === 'failed' && (
          <div className="text-center text-red-600">
            <svg className="w-16 h-16 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <h2 className="mt-4 text-xl font-bold">Payment Failed</h2>
            <p className="mt-2">Please try again or contact support.</p>
            <button
              onClick={() => navigate('/chat')}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Return to Chat
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentCallback;