import React, { useState, useEffect } from 'react';

const AnimatedEllipsis = () => {
  const [dots, setDots] = useState('');
  const [message, setMessage] = useState('Thinking');
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const dotsInterval = setInterval(() => {
      setDots(prev => (prev.length >= 3 ? '' : prev + '.'));
    }, 500);

    const messageInterval = setInterval(() => {
      setElapsedTime(prev => prev + 1);
    }, 1000);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(messageInterval);
    };
  }, []);

  useEffect(() => {
    if (elapsedTime >= 10) {
      setMessage("Almost there....i'm doing some heavy lifting");
    } else if (elapsedTime >= 5) {
      setMessage("Hold up, I'm cooking");
    }
  }, [elapsedTime]);

  return (
    <span className="inline-flex items-center">
      {message}
      <span className="w-6 inline-block">{dots}</span>
    </span>
  );
};

export default AnimatedEllipsis;