// CaseDocument.js - Handle document management for cases
import React, { useState, useCallback } from 'react';
import { FaFile, FaDownload, FaTrash, FaPlus, FaSpinner } from 'react-icons/fa';
import { caseService } from '../../services/caseService';
import { toast } from 'react-toastify';

const CaseDocument = ({ caseId, documents = [] }) => {
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleAddDocument = useCallback(async (file) => {
    try {
      setUploading(true);
      const newDocument = await caseService.uploadDocument(caseId, file);
      toast.success('Document uploaded successfully');
      return newDocument;
    } catch (error) {
      console.error('Error uploading document:', error);
      toast.error('Failed to upload document');
      throw error;
    } finally {
      setUploading(false);
    }
  }, [caseId]);

  const handleFileSelect = async (e) => {
    const files = Array.from(e.target.files || []);
    setSelectedFiles(files);
    
    for (const file of files) {
      try {
        await handleAddDocument(file);
      } catch (error) {
        console.error(`Failed to upload ${file.name}:`, error);
      }
    }
    setSelectedFiles([]);
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    const files = Array.from(e.dataTransfer.files || []);
    setSelectedFiles(files);
    
    for (const file of files) {
      try {
        await handleAddDocument(file);
      } catch (error) {
        console.error(`Failed to upload ${file.name}:`, error);
      }
    }
    setSelectedFiles([]);
  }, [handleAddDocument]);

  const handleDelete = async (documentId) => {
    try {
      await caseService.deleteDocument(caseId, documentId);
      toast.success('Document deleted successfully');
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error('Failed to delete document');
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const getFileIcon = (type) => {
    // Add more file type icons as needed
    switch (type) {
      case 'application/pdf':
        return 'text-red-500';
      case 'image/jpeg':
      case 'image/png':
        return 'text-blue-500';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'text-indigo-500';
      default:
        return 'text-gray-500';
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold">Documents</h3>
        <label className="flex items-center gap-2 text-blue-600 hover:text-blue-700 cursor-pointer">
          <input
            type="file"
            multiple
            className="hidden"
            onChange={handleFileSelect}
            disabled={uploading}
          />
          <FaPlus /> Upload Document
        </label>
      </div>

      <div
        className={`border-2 border-dashed rounded-lg p-8 transition-colors ${
          dragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
        }`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {uploading && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
            <FaSpinner className="animate-spin text-blue-600 text-2xl" />
          </div>
        )}

        <div className="space-y-4">
          {documents.map((doc) => (
            <div
              key={doc.id}
              className="flex items-center justify-between p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
            >
              <div className="flex items-center gap-3">
                <FaFile className={getFileIcon(doc.type)} />
                <div>
                  <div className="font-medium">{doc.name}</div>
                  <div className="text-sm text-gray-500 flex items-center gap-4">
                    <span>{formatFileSize(doc.size)}</span>
                    <span>•</span>
                    <span>{new Date(doc.uploadedAt).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <button 
                  className="p-2 text-gray-600 hover:text-blue-600"
                  onClick={() => window.open(doc.url, '_blank')}
                >
                  <FaDownload />
                </button>
                <button 
                  className="p-2 text-gray-600 hover:text-red-600"
                  onClick={() => handleDelete(doc.id)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>

        {documents.length === 0 && !uploading && (
          <div className="text-center text-gray-500">
            <p className="text-lg mb-2">Drop files here or click to upload</p>
            <p className="text-sm">Supported formats: PDF, Word, Images</p>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <div className="mt-4 p-4 bg-gray-50 rounded-lg">
            <p className="text-sm font-medium mb-2">Uploading {selectedFiles.length} files...</p>
            {selectedFiles.map((file, index) => (
              <div key={index} className="text-sm text-gray-600">
                {file.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseDocument; 