import React from 'react';
import { FaCircle, FaClock, FaUser, FaFolder } from 'react-icons/fa';

const CaseList = ({ cases, onSelectCase, selectedCaseId }) => {
  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active': return 'bg-green-100 text-green-800';
      case 'new': return 'bg-blue-100 text-blue-800';
      case 'pending': return 'bg-yellow-100 text-yellow-800';
      case 'closed': return 'bg-gray-100 text-gray-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  const formatDueDate = (date) => {
    const dueDate = new Date(date);
    const today = new Date();
    const diffTime = dueDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return <span className="text-red-600">Overdue</span>;
    } else if (diffDays === 0) {
      return <span className="text-orange-600">Due today</span>;
    } else if (diffDays === 1) {
      return <span className="text-orange-600">Due tomorrow</span>;
    } else {
      return <span className="text-gray-600">Due in {diffDays} days</span>;
    }
  };

  return (
    <div className="bg-white">
      <h2 className="text-lg font-semibold p-4 border-b flex items-center gap-2">
        <FaFolder className="text-gray-500" />
        Cases ({cases.length})
      </h2>
      <div className="divide-y">
        {cases.map(case_ => (
          <div
            key={case_.id}
            onClick={() => onSelectCase(case_)}
            className={`p-4 cursor-pointer hover:bg-gray-50 transition-colors ${
              selectedCaseId === case_.id ? 'bg-blue-50 border-l-4 border-blue-500' : ''
            }`}
          >
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-medium text-lg">{case_.title}</h3>
              <FaCircle className={`${getPriorityColor(case_.priority)} h-2 w-2`} />
            </div>

            <div className="space-y-2">
              <div className="flex items-center text-sm text-gray-600 gap-2">
                <FaUser className="text-gray-400" />
                <span>{case_.clientName}</span>
              </div>

              <div className="flex items-center text-sm text-gray-600 gap-2">
                <FaClock className="text-gray-400" />
                {formatDueDate(case_.dueDate)}
              </div>

              <div className="flex items-center gap-2">
                <span className={`inline-block px-2 py-1 rounded-full text-xs ${
                  getStatusBadgeColor(case_.status)
                }`}>
                  {case_.status}
                </span>
                <span className={`inline-block px-2 py-1 rounded-full text-xs bg-gray-100 text-gray-800`}>
                  {case_.type}
                </span>
              </div>

              {case_.description && (
                <p className="text-sm text-gray-600 line-clamp-2">
                  {case_.description}
                </p>
              )}

              <div className="flex items-center gap-4 text-xs text-gray-500">
                <span>{case_.documents?.length || 0} documents</span>
                <span>{case_.timeline?.length || 0} events</span>
                <span>{case_.communications?.length || 0} communications</span>
              </div>
            </div>
          </div>
        ))}
        {cases.length === 0 && (
          <div className="p-8 text-center text-gray-500">
            <p className="text-lg mb-2">No cases found</p>
            <p className="text-sm">Create a new case to get started</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseList; 