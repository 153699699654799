import React from 'react';
import { FileText } from 'lucide-react';

export const AttachmentPreview = ({ attachment }) => {
  if (!attachment) return null;

  if (attachment.type?.startsWith('image/')) {
    return (
      <div className="mt-2 max-w-xs">
        <img 
          src={attachment.url} 
          alt={attachment.name}
          className="rounded-lg max-h-48 object-contain"
          loading="lazy"
        />
        <p className="text-xs text-gray-500 mt-1">{attachment.name}</p>
      </div>
    );
  }

  return (
    <div className="mt-2 flex items-center space-x-2">
      <FileText className="h-4 w-4" />
      <span className="text-sm">{attachment.name}</span>
    </div>
  );
};