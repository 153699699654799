import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import AnimatedCounter from './AnimatedCounter';
import { PlusCircle } from 'lucide-react';
import NotificationBell from './NotificationBell';
import Logo from './Logo';

const Navbar = () => {
  const dispatch = useDispatch();
  const { user, credits } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-sm fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-2 sm:px-3 md:px-4 lg:px-6">
        <div className="flex justify-between items-center h-14 sm:h-16 md:h-18 lg:h-20">
          {/* Left Section - Logo with hidden text at 375px */}
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center space-x-1.5 sm:space-x-2 md:space-x-3 lg:space-x-4">
              <Logo width="32" height="32" className="w-6 h-6 sm:w-8 sm:h-8 md:w-9 md:h-9 lg:w-10 lg:h-10" />
              <span className="hidden min-[376px]:inline text-sm sm:text-base md:text-lg lg:text-xl font-bold text-gray-900 dark:text-white">
                ModulawAI
              </span>
            </Link>
          </div>

          {/* Middle Section - Credits with increased prominence at smaller screens */}
          {user && (
            <div className="flex items-center justify-center flex-1 mx-1 min-[376px]:mx-3 md:mx-4 lg:mx-6">
              <div className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white 
                px-1.5 min-[376px]:px-1.5 md:px-2.5 lg:px-3 
                py-1 min-[376px]:py-0.5 md:py-1.5 lg:py-2 
                rounded-lg shadow-sm 
                flex items-center space-x-1 min-[376px]:space-x-1.5 md:space-x-2 lg:space-x-3
                border border-gray-200 dark:border-gray-600
                text-[8px] min-[376px]:text-xs md:text-sm lg:text-base"
              >
                <span className="font-semibold uppercase tracking-tight min-[376px]:tracking-normal whitespace-nowrap">
                  Credits
                </span>
                <div className={`${credits < 100 ? 'text-red-500' : 'text-green-500'} 
                  font-bold flex items-center whitespace-nowrap`}
                >
                  <AnimatedCounter value={credits} />
                  <button
                    onClick={() => navigate('/profile?tab=subscription')}
                    className="ml-1 min-[376px]:ml-1 md:ml-1.5 lg:ml-2 
                      hover:bg-gray-200 dark:hover:bg-gray-600 
                      p-0.5 min-[376px]:p-0.5 rounded-full transition-colors"
                    title="Add more credits"
                  >
                    <PlusCircle className="h-2 w-2 min-[376px]:h-2.5 min-[376px]:w-2.5 md:h-3 md:w-3 lg:h-4 lg:w-4" />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Right Section - Desktop Navigation Links */}
          <div className="hidden md:flex items-center space-x-2 md:space-x-3 lg:space-x-4">
            <button
              onClick={toggleTheme}
              className="p-1 md:p-1.5 lg:p-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
              aria-label="Toggle theme"
            >
              {isDarkMode ? (
                <FaSun className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" />
              ) : (
                <FaMoon className="w-4 h-4 md:w-5 md:h-5 lg:w-6 lg:h-6" />
              )}
            </button>

            {user && (
              <>
                <NotificationBell />
                <Link
                  to="/chat"
                  className="text-sm md:text-base lg:text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
                >
                  Chat
                </Link>
                <button
                  onClick={handleLogout}
                  className="inline-flex items-center px-2.5 md:px-3 lg:px-4 
                    py-1 md:py-1.5 lg:py-2
                    border border-transparent text-sm md:text-base lg:text-lg font-medium 
                    rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                  Logout
                </button>
              </>
            )}
            
            {!user && (
              <>
                <Link to="/pricing" className="text-sm md:text-base lg:text-lg text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white">
                  Pricing
                </Link>
                <Link
                  to="/login"
                  className="text-sm md:text-base lg:text-lg text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white 
                    px-2 md:px-3 lg:px-4 py-1 md:py-1.5 lg:py-2"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="inline-flex items-center px-2.5 md:px-3 lg:px-4 
                    py-1 md:py-1.5 lg:py-2
                    border border-transparent text-sm md:text-base lg:text-lg font-medium 
                    rounded-md text-white bg-blue-600 hover:bg-blue-700"
                >
                  Sign up
                </Link>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMobileMenu}
            className="md:hidden p-1.5 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800"
            aria-expanded={isMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-5 w-5 sm:h-6 sm:w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu Panel */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden border-t border-gray-200 dark:border-gray-700`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white dark:bg-gray-800 shadow-lg">
          <button
            onClick={toggleTheme}
            className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700"
          >
            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </button>

          {user && (
            <>
              <div className="px-3 py-2 flex items-center justify-between">
                <NotificationBell />
              </div>
              <Link
                to="/chat"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Chat
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
                className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-red-600 hover:text-red-700 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Logout
              </button>
            </>
          )}
          
          {!user && (
            <>
              <Link
                to="/pricing"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Pricing
              </Link>
              <Link
                to="/login"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Login
              </Link>
              <Link
                to="/signup"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-3 py-2 rounded-md text-base font-medium text-blue-600 hover:text-blue-700 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Sign up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;