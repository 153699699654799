import React from 'react';

const CurrentCreditsStatus = ({ credits, expiryDate }) => {
  return (
    <div className="mt-8 bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
      <div className="flex justify-between items-center">
        <div>
          <h4 className="text-sm font-medium text-gray-900 dark:text-white">Current Balance</h4>
          <p className="text-2xl font-bold text-blue-600 dark:text-blue-400">
            {credits?.toLocaleString()} credits
          </p>
        </div>
        <div>
          <h4 className="text-sm font-medium text-gray-900 dark:text-white">Expires On</h4>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {expiryDate 
              ? new Date(expiryDate).toLocaleDateString()
              : 'Never'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrentCreditsStatus; 