import React, { useState, useEffect, useCallback } from 'react';
import { UserCircle } from 'lucide-react';
import toast from 'react-hot-toast';
import api from '../../services/api';

const WorkspaceMembers = ({ workspace, handleRoleChange, handleRemoveMember }) => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [creditInputs, setCreditInputs] = useState({});

  const fetchMembers = useCallback(async () => {
    if (!workspace?._id) return;

    try {
      console.log('Fetching members for workspace:', workspace._id);
      const response = await api.get(`/api/workspaces/${workspace._id}/members`);
      
      if (response.data.success) {
        setMembers(response.data.data.members);
      } else {
        throw new Error(response.data.message || 'Failed to fetch members');
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      toast.error(error.response?.data?.message || 'Failed to fetch workspace members');
    } finally {
      setIsLoading(false);
    }
  }, [workspace?._id]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const handleCreditAssignment = async (memberId, amount) => {
    const credits = parseInt(amount);
    if (isNaN(credits) || credits <= 0) {
      toast.error('Please enter a valid positive number');
      return;
    }

    console.log('Assigning credits:', { 
      memberId, 
      credits,
      memberDetails: members.find(m => m._id === memberId)
    });

    const ownerCredits = workspace?.owner?.credits?.balance || 0;
    if (credits > ownerCredits) {
      toast.error('Cannot assign more credits than available in organization balance');
      return;
    }

    setIsLoading(true);
    try {
      // Make sure we're using the correct member ID
      const actualMemberId = members.find(m => m._id === memberId)?.user?._id;
      if (!actualMemberId) {
        throw new Error('Invalid member ID');
      }

      const response = await api.post(`/api/workspaces/${workspace._id}/assign-credits`, {
        memberId: actualMemberId,
        credits
      });

      if (response.data.success) {
        // Update local state to reflect the changes
        setMembers(prevMembers => prevMembers.map(member => {
          if (member._id === memberId) {
            return {
              ...member,
              user: {
                ...member.user,
                credits: {
                  ...member.user?.credits,
                  balance: (member.user?.credits?.balance || 0) + credits
                }
              }
            };
          }
          return member;
        }));

        // Update workspace owner's credits
        if (workspace.owner) {
          workspace.owner.credits = {
            ...workspace.owner.credits,
            balance: ownerCredits - credits
          };
        }

        toast.success('Credits assigned successfully');
        setCreditInputs(prev => ({
          ...prev,
          [memberId]: ''
        }));
      }
    } catch (error) {
      console.error('Error assigning credits:', error);
      toast.error(error.response?.data?.message || 'Failed to assign credits');
    } finally {
      setIsLoading(false);
    }
  };

  const renderMemberCredits = (member) => {
    return member.user?.credits?.balance || 0;
  };

  const canAssignCredits = () => {
    if (!workspace) return false;
    
    const currentUserMember = workspace.members.find(m => 
      m.user._id === workspace.owner._id
    );
    
    const isOwnerOrAdmin = 
      workspace.owner._id === currentUserMember?.user._id || 
      ['SUPER_ADMIN', 'ORGANIZATION_ADMIN'].includes(currentUserMember?.role);

    return isOwnerOrAdmin;
  };

  const renderCreditAssignment = (member) => {
    // Check if the member is the workspace owner
    const isOwner = member.user._id === workspace?.owner?._id;
    const hasPermission = canAssignCredits();

    if (isOwner || !hasPermission) {
      return (
        <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
          {isOwner ? 'N/A' : 'No permission'}
        </td>
      );
    }

    return (
      <td className="px-4 py-4 whitespace-nowrap">
        <div className="flex items-center space-x-2">
          <input
            type="number"
            min="0"
            value={creditInputs[member._id] || ''}
            onChange={(e) => setCreditInputs(prev => ({
              ...prev,
              [member._id]: e.target.value
            }))}
            className="w-20 px-2 py-1 text-sm border rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            placeholder="Amount"
          />
          <button
            onClick={() => handleCreditAssignment(member._id, creditInputs[member._id])}
            disabled={isLoading || !creditInputs[member._id]}
            className="px-2 py-1 text-xs text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            Assign
          </button>
        </div>
      </td>
    );
  };

  const handleRoleUpdate = async (memberId, newRole) => {
    try {
      setIsLoading(true);
      
      // Get the actual user ID from the member object
      const member = members.find(m => m._id === memberId);
      if (!member) {
        throw new Error('Member not found');
      }

      const actualUserId = member.user._id;
      
      // Make API call using the api utility instead of fetch
      const response = await api.patch(
        `/api/workspaces/${workspace._id}/members/${actualUserId}/role`,
        { role: newRole }
      );

      if (response.data.success) {
        // Call the parent handler to update the UI
        handleRoleChange?.(memberId, newRole);
        toast.success('Role updated successfully');
        await fetchMembers(); // Refresh the members list
      } else {
        throw new Error(response.data.message || 'Failed to update role');
      }
    } catch (error) {
      console.error('Error updating role:', error);
      toast.error(error.response?.data?.message || 'Failed to update role');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="py-8">
      <div className="mb-6">
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700 table-fixed">
                <thead className="bg-gray-50 dark:bg-gray-800">
                  <tr>
                    <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Member
                    </th>
                    <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Role
                    </th>
                    <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      User Credits
                    </th>
                    {canAssignCredits() && (
                      <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Assign Credits
                      </th>
                    )}
                    <th className="w-1/6 px-4 py-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                  {members.map((member) => (
                    <tr key={`${member._id}-${member.user._id}`}>
                      <td className="px-4 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <UserCircle className="w-8 h-8 text-gray-400" />
                          <div className="ml-3">
                            <div className="text-sm font-medium text-gray-900 dark:text-white">
                              {member.user.firstName} {member.user.lastName}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {member.user.email}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <select
                          value={member.role}
                          onChange={(e) => handleRoleUpdate(member._id, e.target.value)}
                          className="block w-full px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md dark:bg-gray-800 dark:border-gray-700 dark:text-white"
                          disabled={member.user._id === workspace?.owner?._id} // Disable for workspace owner
                        >
                          <option value="TEAM_MEMBER">Team Member</option>
                          <option value="CASE_MANAGER">Case Manager</option>
                          <option value="ORGANIZATION_ADMIN">Organization Admin</option>
                          <option value="CLIENT">Client</option>
                          <option value="AUDITOR">Auditor</option>
                        </select>
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                        {renderMemberCredits(member)}
                      </td>
                      {renderCreditAssignment(member)}
                      <td className="px-4 py-4 whitespace-nowrap">
                        <button
                          onClick={() => handleRemoveMember?.(member._id)}
                          className="text-red-600 hover:text-red-900 text-sm font-medium"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceMembers; 