import api from './api';

export const caseService = {
  // Case Management
  getAllCases: async () => {
    try {
      const response = await api.get('/api/case-manager/cases');
      return response.data;
    } catch (error) {
      console.error('Error fetching cases:', error);
      throw error;
    }
  },

  createCase: async (caseData) => {
    try {
      const response = await api.post('/api/cases', caseData);
      return response.data;
    } catch (error) {
      console.error('Error creating case:', error.response || error);
      throw error;
    }
  },

  // Document Management
  uploadDocument: async (caseId, file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await api.post(
        `/api/documents/${caseId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error uploading document:', error);
      throw error;
    }
  },

  deleteDocument: async (caseId, documentId) => {
    try {
      await api.delete(`/api/documents/${caseId}/${documentId}`);
    } catch (error) {
      console.error('Error deleting document:', error);
      throw error;
    }
  },

  // Timeline Management
  addTimelineEvent: async (caseId, eventData) => {
    try {
      const response = await api.post(
        `/api/timeline/${caseId}`,
        eventData
      );
      return response.data;
    } catch (error) {
      console.error('Error adding timeline event:', error);
      throw error;
    }
  },

  // Search functionality
  searchCases: async (query) => {
    try {
      const response = await api.get(`/api/cases/search`, {
        params: { q: query }
      });
      return response.data;
    } catch (error) {
      console.error('Error searching cases:', error);
      throw error;
    }
  }
}; 