import React from 'react';
import { Copy, ThumbsUp, ThumbsDown } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { SpeechButton } from './';

const MessageActions = ({ 
  content, 
  isDarkMode, 
  messageFeedback, 
  index, 
  onFeedback, 
}) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Message copied to clipboard', {
      duration: 2000,
      position: 'bottom-center',
      style: {
        background: isDarkMode ? '#374151' : '#ffffff',
        color: isDarkMode ? '#ffffff' : '#000000',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      },
    });
  };

  return (
    <div className="flex items-center space-x-2 mt-2">
      <button
        onClick={() => copyToClipboard(content)}
        className="p-1 hover:bg-gray-300 dark:hover:bg-gray-600 rounded-md transition-colors text-yellow-500"
        title="Copy message"
      >
        <Copy className="h-4 w-4" />
      </button>
      
      <SpeechButton text={content} />

      <div className="flex items-center space-x-1 ml-2">
        <button
          onClick={() => onFeedback(index, true)}
          className={`p-1 hover:bg-gray-300 dark:hover:bg-gray-600 rounded-md transition-colors ${
            messageFeedback[index] === true ? 'text-blue-500' : 'text-gray-500'
          }`}
          title="Helpful"
        >
          <ThumbsUp className="h-4 w-4" />
        </button>
        <button
          onClick={() => onFeedback(index, false)}
          className={`p-1 hover:bg-gray-300 dark:hover:bg-gray-600 rounded-md transition-colors ${
            messageFeedback[index] === false ? 'text-red-500' : 'text-gray-500'
          }`}
          title="Not helpful"
        >
          <ThumbsDown className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default MessageActions; 