import React, { useState } from 'react';
import { FaTimes, FaPhone, FaEnvelope, FaCalendar } from 'react-icons/fa';

const CommunicationForm = ({ type, onSubmit, onClose, client }) => {
  const [formData, setFormData] = useState({
    type: type,
    subject: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    time: new Date().toTimeString().slice(0, 5),
    duration: '30',
    recipients: [client.email],
    attachments: []
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      timestamp: new Date(`${formData.date}T${formData.time}`).toISOString()
    });
    onClose();
  };

  const getFormTitle = () => {
    switch (type) {
      case 'call': return 'Log Phone Call';
      case 'email': return 'Send Email';
      case 'meeting': return 'Schedule Meeting';
      default: return 'New Communication';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'call': return <FaPhone className="text-blue-600" />;
      case 'email': return <FaEnvelope className="text-blue-600" />;
      case 'meeting': return <FaCalendar className="text-blue-600" />;
      default: return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex items-center gap-2">
            {getIcon()}
            <h3 className="text-lg font-semibold">{getFormTitle()}</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {type === 'email' && (
            <div>
              <label className="block text-sm font-medium mb-1">Subject</label>
              <input
                type="text"
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                value={formData.subject}
                onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                required
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium mb-1">
              {type === 'email' ? 'Message' : 'Description'}
            </label>
            <textarea
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              rows="4"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Date</label>
              <input
                type="date"
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Time</label>
              <input
                type="time"
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                value={formData.time}
                onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                required
              />
            </div>
          </div>

          {type === 'meeting' && (
            <div>
              <label className="block text-sm font-medium mb-1">Duration (minutes)</label>
              <select
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                value={formData.duration}
                onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
              >
                <option value="15">15 minutes</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">1 hour</option>
                <option value="90">1.5 hours</option>
                <option value="120">2 hours</option>
              </select>
            </div>
          )}

          {type === 'email' && (
            <div>
              <label className="block text-sm font-medium mb-1">Attachments</label>
              <input
                type="file"
                multiple
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                onChange={(e) => setFormData({ ...formData, attachments: Array.from(e.target.files) })}
              />
            </div>
          )}

          <div className="flex justify-end gap-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              {type === 'email' ? 'Send' : type === 'meeting' ? 'Schedule' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommunicationForm; 