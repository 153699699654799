export const clientService = {
  searchClients: async (query) => {
    try {
      const response = await fetch(`/api/clients/search?q=${encodeURIComponent(query)}`);
      if (!response.ok) throw new Error('Failed to search clients');
      return await response.json();
    } catch (error) {
      console.error('Error searching clients:', error);
      throw error;
    }
  },

  createClient: async (clientData) => {
    try {
      const response = await fetch('/api/clients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientData),
      });
      if (!response.ok) throw new Error('Failed to create client');
      return await response.json();
    } catch (error) {
      console.error('Error creating client:', error);
      throw error;
    }
  },
}; 