import React, { useState } from 'react';
import { Check, X } from 'lucide-react';
import { useSelector } from 'react-redux';
import api from '../services/api';

const PricingModal = ({ isOpen, onClose }) => {
  const { user } = useSelector((state) => state.auth);
  
  const [isAnnual, setIsAnnual] = useState(user?.plan?.billingCycle === 'annual');
  const [showContactForm, setShowContactForm] = useState(false);
  const [error, setError] = useState(null);

  const plans = [
    {
      name: 'Essential',
      monthlyPrice: '₦30,000',
      annualPrice: '₦300,000',
      savings: '₦60,000',
      credits: '800,000 AI Credits/month',
      description: 'Limited usage for brief queries and smaller research tasks',
      features: [
        'Basic access to case law database',
        'Up to 200 pages of document intelligence/month',
        'Standard response speed',
        'Email support',
      ],
    },
    {
      name: 'Professional',
      monthlyPrice: '₦50,000',
      annualPrice: '₦500,000',
      savings: '₦100,000',
      credits: '2,000,000 AI Credits/month',
      description: 'For users with moderate usage needs',
      features: [
        'Essential plan features +',
        'Full access to case law and legal databases',
        'Up to 500 pages of document intelligence/month',
        'Faster response speed with priority support',
        'Advanced legal reasoning and query refinement tools',
        'Chat and email support',
        'Team collaboration for 1 member',
      ],
      popular: true,
    },
    {
      name: 'Enterprise',
      description: 'Suitable for high-demand legal work and team collaboration',
      credits: 'Pay as you go credits',
      creditNote: 'No expiration on credits',
      features: [
        'Professional plan features +',
        'Unlimited access to all databases and resources',
        'Unlimited document uploads',
        'Priority response speed and support',
        'Admin credit distribution to team members',
        'Team collaboration for up to 4 members',
        'Dedicated account manager',
        'Pay as you go document intelligence',
        'Your own API usage (Coming Soon)',
      ],
    },
  ];

  const handleSubscribe = async (planName) => {
    if (planName === 'Enterprise') {
      setShowContactForm(true);
      return;
    }

    try {
      setError(null);
      const response = await api.post(
        '/api/payments/initialize-subscription',
        {
          plan: planName,
          billing: isAnnual ? 'annual' : 'monthly',
          isUpgrade: user?.plan?.status === 'active'
        }
      );

      if (response.data.status) {
        window.location.href = response.data.data.authorization_url;
      }
    } catch (error) {
      console.error('Payment initialization failed:', error);
      setError(error.response?.data?.error || 'Failed to initialize payment');
    }
  };

  const ContactForm = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900 rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-white">Contact Sales Team</h3>
          <button 
            onClick={() => setShowContactForm(false)}
            className="text-gray-400 hover:text-white"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Company Name
            </label>
            <input
              type="text"
              className="w-full bg-gray-800 border border-gray-700 rounded-md p-2 text-white"
              placeholder="Your company name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Team Size
            </label>
            <input
              type="number"
              className="w-full bg-gray-800 border border-gray-700 rounded-md p-2 text-white"
              placeholder="Number of team members"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Message
            </label>
            <textarea
              className="w-full bg-gray-800 border border-gray-700 rounded-md p-2 text-white"
              rows="4"
              placeholder="Tell us about your needs"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition-colors"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );

  const ErrorMessage = () => error ? (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
      <span className="block sm:inline">{error}</span>
    </div>
  ) : null;

  const isCurrentPlan = (planName) => {
    return user?.plan?.type === planName && 
           user?.plan?.status === 'active';
  };

  const getPlanButtonText = (plan) => {
    const isCurrentType = isCurrentPlan(plan.name);
    const currentBillingMatches = user?.plan?.billingCycle === (isAnnual ? 'annual' : 'monthly');

    if (isCurrentType && currentBillingMatches) {
      return 'Current Plan';
    }
    if (isCurrentType && !currentBillingMatches) {
      return `Switch to ${isAnnual ? 'Annual' : 'Monthly'}`;
    }
    return user?.plan?.status === 'active' ? 'Switch Plan' : 'Subscribe Now';
  };

  const renderPlanButton = (plan) => {
    const isCurrentType = isCurrentPlan(plan.name);
    const currentBillingMatches = user?.plan?.billingCycle === (isAnnual ? 'annual' : 'monthly');
    const isDisabled = isCurrentType && currentBillingMatches;

    return (
      <button
        onClick={() => handleSubscribe(plan.name)}
        disabled={isDisabled}
        className={`w-full py-2 rounded-md transition-colors ${
          isDisabled
            ? 'bg-gray-600 cursor-not-allowed text-gray-300'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
      >
        {getPlanButtonText(plan)}
      </button>
    );
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <>
      {/* Main Modal */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4" 
        onClick={onClose}
      >
        <div 
          className="bg-gray-900 rounded-lg max-w-6xl w-full max-h-[90vh] overflow-y-auto relative" 
          onClick={handleModalClick}
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-white">Upgrade Your Plan</h2>
              <button 
                onClick={onClose}
                className="p-2 hover:bg-gray-800 rounded-full text-gray-400 hover:text-white"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 mb-8">
              <span className={`text-lg ${!isAnnual ? 'text-blue-400 font-semibold' : 'text-gray-400'}`}>
                Monthly
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isAnnual}
                  onChange={() => setIsAnnual(!isAnnual)}
                />
                <div className="w-14 h-7 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
              <span className={`text-lg ${isAnnual ? 'text-blue-400 font-semibold' : 'text-gray-400'}`}>
                Annual
              </span>
              {isAnnual && (
                <span className="bg-green-900 text-green-300 text-xs font-medium px-2.5 py-0.5 rounded">
                  Save up to 20%
                </span>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {plans.map((plan) => {
                const isCurrentType = isCurrentPlan(plan.name);
                const currentBillingMatches = user?.plan?.billingCycle === (isAnnual ? 'annual' : 'monthly');
                const isExactCurrentPlan = isCurrentType && currentBillingMatches;

                return (
                  <div 
                    key={plan.name}
                    className={`relative bg-gray-800 rounded-lg p-6 border ${
                      isExactCurrentPlan 
                        ? 'opacity-70 border-gray-600' 
                        : plan.popular 
                          ? 'border-blue-500 shadow-lg' 
                          : 'border-gray-700'
                    }`}
                  >
                    {plan.popular && (
                      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                        <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-semibold">
                          Most Popular
                        </span>
                      </div>
                    )}
                    
                    <h3 className="text-xl font-bold text-white mb-2">
                      {plan.name}
                      {isCurrentPlan(plan.name) && (
                        <span className="ml-2 text-sm font-normal text-gray-400">
                          (Current Plan)
                        </span>
                      )}
                    </h3>
                    {plan.name === 'Enterprise' ? (
                      <div className="mb-4">
                        <span className="text-2xl font-bold text-white">Contact Us</span>
                        <p className="text-sm text-gray-400 mt-1">{plan.creditNote}</p>
                      </div>
                    ) : (
                      <div className="mb-4">
                        <span className="text-2xl font-bold text-white">
                          {isAnnual ? plan.annualPrice : plan.monthlyPrice}
                        </span>
                        <span className="text-gray-400 ml-1">
                          {isAnnual ? '/ year' : '/ month'}
                        </span>
                        {isAnnual && plan.savings && (
                          <p className="text-green-400 text-sm mt-1">
                            Save {plan.savings} yearly
                          </p>
                        )}
                      </div>
                    )}
                    
                    <p className="text-gray-300 mb-4">{plan.description}</p>
                    <ul className="space-y-3 mb-6">
                      {plan.features.map((feature) => (
                        <li key={feature} className="flex items-center gap-2">
                          <Check className="h-5 w-5 text-green-400" />
                          <span className="text-gray-300">{feature}</span>
                        </li>
                      ))}
                    </ul>
                    
                    {plan.name !== 'Enterprise' && renderPlanButton(plan)}
                    {plan.name === 'Enterprise' && (
                      <button
                        onClick={() => handleSubscribe(plan.name)}
                        className="w-full py-2 rounded-md transition-colors bg-blue-600 text-white hover:bg-blue-700"
                      >
                        Contact Sales
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Contact Form Modal - Only show when showContactForm is true */}
      {showContactForm && <ContactForm />}

      {/* Error Message */}
      {error && <ErrorMessage />}
    </>
  );
};

export default PricingModal; 