import React from 'react';
import { Key } from 'lucide-react';

const PasswordForm = ({ user, passwordInfo, setPasswordInfo, handlePasswordChange, isLoading }) => {
  return (
    <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700 max-w-2xl">
      <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
        Change Password
      </h3>
      <form onSubmit={handlePasswordChange} className="space-y-4">
        {/* Hidden username field for accessibility */}
        <input
          type="text"
          autoComplete="username"
          value={user?.email || ''}
          style={{ display: 'none' }}
          readOnly
        />

        {/* Current Password */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Current Password
          </label>
          <div className="mt-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Key className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="password"
              value={passwordInfo.currentPassword}
              onChange={(e) => setPasswordInfo({ ...passwordInfo, currentPassword: e.target.value })}
              className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              autoComplete="current-password"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {/* New Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              New Password
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Key className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="password"
                value={passwordInfo.newPassword}
                onChange={(e) => setPasswordInfo({ ...passwordInfo, newPassword: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                autoComplete="new-password"
              />
            </div>
          </div>

          {/* Confirm Password */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Confirm Password
            </label>
            <div className="mt-1 relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Key className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="password"
                value={passwordInfo.confirmPassword}
                onChange={(e) => setPasswordInfo({ ...passwordInfo, confirmPassword: e.target.value })}
                className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>

        {/* Update Password Button */}
        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          <Key className="w-4 h-4 mr-2" />
          {isLoading ? 'Updating...' : 'Update Password'}
        </button>
      </form>
    </div>
  );
};

export default PasswordForm; 