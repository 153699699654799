import { useState, useRef } from 'react';
import { useTheme } from '../providers/ThemeProvider';
import toast from 'react-hot-toast';
import { AZURE_CONFIG } from '../config/azure-config';

export const useSpeechSynthesis = () => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { isDarkMode } = useTheme();
  const audioRef = useRef(new Audio());

  const speak = async (text) => {
    if (isSpeaking) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsSpeaking(false);
      return;
    }

    try {
      // Get audio from Azure TTS API
      const response = await fetch(
        `https://${AZURE_CONFIG.region}.tts.speech.microsoft.com/cognitiveservices/v1`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': AZURE_CONFIG.key,
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3'
          },
          body: `
            <speak version='1.0' xml:lang='${AZURE_CONFIG.language}'>
              <voice xml:lang='${AZURE_CONFIG.language}' name='${AZURE_CONFIG.voice}'>
                ${text}
              </voice>
            </speak>
          `
        }
      );

      if (!response.ok) {
        throw new Error('Speech synthesis failed');
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      
      audioRef.current.src = audioUrl;
      audioRef.current.onplay = () => setIsSpeaking(true);
      audioRef.current.onended = () => {
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
      };
      audioRef.current.onerror = () => {
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
        showErrorToast();
      };

      audioRef.current.play();
    } catch (error) {
      console.error('Speech synthesis error:', error);
      showErrorToast();
    }
  };

  const showErrorToast = () => {
    toast.error('Speech synthesis failed', {
      duration: 2000,
      position: 'bottom-center',
      style: {
        background: isDarkMode ? '#374151' : '#ffffff',
        color: isDarkMode ? '#ffffff' : '#000000',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      },
    });
  };

  return {
    isSpeaking,
    speak
  };
};