import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';
// Create async thunks
export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/auth/login', credentials, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.data || !response.data.token) {
        return rejectWithValue('Invalid response from server');
      }

      return response.data;
    } catch (error) {
      console.error('Login error details:', error);
      
      if (error.response) {
        // Server responded with error
        return rejectWithValue(error.response.data?.message || 'Login failed');
      } else if (error.request) {
        // Request made but no response
        return rejectWithValue('No response from server');
      } else {
        // Request setup error
        return rejectWithValue('Error making request');
      }
    }
  }
);

export const fetchProfile = createAsyncThunk(
  'auth/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/auth/profile');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch profile');
    }
  }
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.put('/api/auth/profile', userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update profile');
    }
  }
);

export const deleteAccount = createAsyncThunk(
  'auth/deleteAccount',
  async (_, { rejectWithValue }) => {
    try {
      await api.delete('/api/user/account');
      return true;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete account');
    }
  }
);

export const fetchCredits = createAsyncThunk(
  'auth/fetchCredits',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/user/credits');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch credits');
    }
  }
);

const loadInitialState = () => {
  try {
    const persistedAuth = localStorage.getItem('auth');
    return persistedAuth ? JSON.parse(persistedAuth) : {
      user: null,
      token: null,
      status: 'idle',
      error: null
    };
  } catch (error) {
    console.error('Error loading auth state:', error);
    return {
      user: null,
      token: null,
      status: 'idle',
      error: null
    };
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ...loadInitialState(),
    credits: 0,
    creditsStatus: 'idle',
    creditsError: null
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = {
        ...action.payload.user,
        phoneNumber: action.payload.user.phoneNumber,
        plan: {
          ...action.payload.user.plan,
          type: action.payload.user.plan?.type || 'Trial',
          status: action.payload.user.plan?.status || 'inactive',
          billingCycle: action.payload.user.plan?.billingCycle || 'monthly'
        }
      };
      state.token = action.payload.token;
      state.status = 'succeeded';
      state.error = null;
      localStorage.setItem('auth', JSON.stringify({
        user: state.user,
        token: action.payload.token,
        status: 'succeeded',
        error: null
      }));
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.error = null;
      localStorage.removeItem('auth');
    },
    updateProfileSuccess: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
        plan: {
          ...state.user.plan,
          ...action.payload.plan
        }
      };
      localStorage.setItem('auth', JSON.stringify(state));
    },
    deleteAccountSuccess: (state) => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.error = null;
    },
    updateCredits: (state, action) => {
      state.credits = action.payload;
      const authData = JSON.parse(localStorage.getItem('auth') || '{}');
      authData.user = {
        ...authData.user,
        credits: { balance: action.payload }
      };
      localStorage.setItem('auth', JSON.stringify(authData));
    }
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = {
          ...action.payload.user,
          phoneNumber: action.payload.user.phoneNumber,
          plan: {
            ...action.payload.user.plan,
            type: action.payload.user.plan?.type || 'Trial',
            status: action.payload.user.plan?.status || 'inactive',
            billingCycle: action.payload.user.plan?.billingCycle || 'monthly'
          }
        };
        state.token = action.payload.token;
        state.error = null;
        state.creditsStatus = 'idle';
        localStorage.setItem('auth', JSON.stringify({
          user: state.user,
          token: action.payload.token,
          status: 'succeeded',
          error: null
        }));
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Fetch Profile
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          ...action.payload,
          phoneNumber: action.payload.phoneNumber,
          plan: {
            ...state.user.plan,
            ...action.payload.plan
          }
        };
        localStorage.setItem('auth', JSON.stringify(state));
        state.credits = action.payload.credits?.balance || 0;
      })
      // Update Profile
      .addCase(updateProfile.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.user = {
            ...state.user,
            ...action.payload.data,
            plan: {
              ...state.user.plan,
              type: state.user.plan?.type || 'Trial',
              status: state.user.plan?.status || 'inactive',
              billingCycle: state.user.plan?.billingCycle || 'monthly'
            },
            credits: {
              ...state.user.credits,
              balance: action.payload.data.credits?.balance || state.user.credits?.balance,
              expiresAt: action.payload.data.credits?.expiresAt || state.user.credits?.expiresAt
            }
          };
          localStorage.setItem('auth', JSON.stringify(state));
        }
      })
      // Add delete account cases
      .addCase(deleteAccount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.status = 'succeeded';
        state.user = null;
        state.token = null;
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCredits.pending, (state) => {
        if (state.creditsStatus === 'idle') {
          state.creditsStatus = 'loading';
        }
      })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.creditsStatus = 'succeeded';
        state.credits = action.payload.credits;
        state.creditsError = null;
      })
      .addCase(fetchCredits.rejected, (state, action) => {
        state.creditsStatus = 'failed';
        state.creditsError = action.payload;
      });
  }
});

export const { loginSuccess, logout, updateProfileSuccess, deleteAccountSuccess, updateCredits } = authSlice.actions;
export default authSlice.reducer;
