import React from 'react';
import { Volume2, Square } from 'lucide-react';
import { useSpeechSynthesis } from '../../hooks/useSpeechSynthesis';

export const SpeechButton = ({ text }) => {
  const { isSpeaking, speak } = useSpeechSynthesis();

  return (
    <button
      onClick={() => speak(text)}
      className={`p-1 hover:bg-gray-300 dark:hover:bg-gray-600 rounded-md transition-colors 
        ${isSpeaking ? 'text-red-500' : 'text-yellow-500'}`}
      title={isSpeaking ? "Stop speaking" : "Read message aloud"}
    >
      <div className="relative">
        {isSpeaking ? (
          <Square className="h-4 w-4" />
        ) : (
          <Volume2 className="h-4 w-4" />
        )}
        {isSpeaking && (
          <div className="absolute -right-1 -top-1 w-2 h-2 bg-red-500 rounded-full animate-pulse" />
        )}
      </div>
    </button>
  );
};